export const segments = [
  {
    value: '0d8a2839-ee11-4f7f-8528-ed3a08b90338',
    label: 'Cesta básica',
  },
  {
    value: 'ce23db6d-be9c-440d-9e57-52693b4e6d62',
    label: 'Medicamentos',
  },
  {
    value: '779679bb-9a0c-4268-91b8-ac39bed8f820',
    label: 'Materiais, produtos e utensílios médico, hospitalar e laboratorial',
  },
  {
    value: '077b9b3d-3258-4a30-b221-d9248f714db5',
    label: 'Produtos de higiene, limpeza e higiene pessoal',
  },
  {
    value: 'ce23db6d-be9c-440d-9e57-52693b4e6d62',
    label: 'Medicamentos',
  },
];
