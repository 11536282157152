export const dadosSuporteMock = {
  tipoLance: [
    {
      id: 1,
      name: 'Global do Lote',
    },
    {
      id: 2,
      name: 'Unitário para o Item',
    },
  ],
  fichaTecnica: [
    {
      id: 1,
      name: 'Não exigir de nenhum participante',
    },
    {
      id: 2,
      name: 'Para todos os participantes no cadastro da proposta',
    },
    {
      id: 3,
      name: 'Somente do vencedor na fase de aceitação',
    },
  ],
  configuracaoParticipacao: [
    {
      id: 1,
      name: 'Ampla participação',
    },
    {
      id: 2,
      name: 'Cota diferenciada de 25% para ME-EPP',
    },
    {
      id: 3,
      name: 'Exclusiva participação ME-EPP',
    },
  ],
  variacaoMinimaLances: [
    {
      id: 1,
      name: 'Em reais',
    },
    {
      id: 2,
      name: 'Em percentual',
    },
  ],
  unidadeMedida: [
    {
      descricao: 'Adesivo',
      sigla: 'ADE',
      codigo: 111,
      id: 1,
      uniqueId: 'e4c8c7fe-72db-4dce-815b-4ae15d6b5ff7',
    },
    {
      descricao: 'Amarrado',
      sigla: 'AMA',
      codigo: 112,
      id: 2,
      uniqueId: '0cbf6200-aa99-421c-b702-948f5283b709',
    },
    {
      descricao: 'Ampola',
      sigla: 'AMP',
      codigo: 113,
      id: 3,
      uniqueId: '412f1aa6-dff3-449d-95c0-e67ecdcd0020',
    },
    {
      descricao: 'Ampola',
      sigla: 'Am',
      codigo: 31,
      id: 4,
      uniqueId: '4b54125b-0366-461f-ad5b-b0dbb2331eaf',
    },
    {
      descricao: 'Anual',
      sigla: 'Ano',
      codigo: 77,
      id: 5,
      uniqueId: 'd4b5d6d0-a709-4fcb-a7cf-6aded402cf54',
    },
    {
      descricao: 'Arroba',
      sigla: '@',
      codigo: 11,
      id: 6,
      uniqueId: '77fcf56d-7591-46fd-b0b2-91c6f471846d',
    },
    {
      descricao: 'Balde',
      sigla: 'BD',
      codigo: 60,
      id: 7,
      uniqueId: 'ca454fac-f11b-48db-891f-e3eb53dc41f6',
    },
    {
      descricao: 'Baldes',
      sigla: 'BAL',
      codigo: 114,
      id: 8,
      uniqueId: '519f822b-c370-4cff-b332-42ed8b3241ea',
    },
    {
      descricao: 'Bandeja',
      sigla: 'BAN',
      codigo: 115,
      id: 9,
      uniqueId: 'd6fea95e-4291-4466-bd52-4fde9e7bae3f',
    },
    {
      descricao: 'Bandeja',
      sigla: 'Ban',
      codigo: 71,
      id: 10,
      uniqueId: '0a38085d-69da-4dc2-b7ab-d26b6f6f1911',
    },
    {
      descricao: 'Barra',
      sigla: 'Br',
      codigo: 62,
      id: 11,
      uniqueId: '18b6e9cd-0ea5-41da-9496-a2153f59ed44',
    },
    {
      descricao: 'Barra',
      sigla: 'BAR',
      codigo: 116,
      id: 12,
      uniqueId: 'd6a8aad0-a72b-41d9-8dee-46f7bc27dbff',
    },
    {
      descricao: 'Barrica',
      sigla: 'BRC',
      codigo: 125,
      id: 13,
      uniqueId: 'a745d91b-3b03-4779-a793-13dffcf2ec15',
    },
    {
      descricao: 'Bastao',
      sigla: 'BAS',
      codigo: 117,
      id: 14,
      uniqueId: '3783d95f-163f-4bfa-ab24-f78e7d12c150',
    },
    {
      descricao: 'Bisnaga',
      sigla: 'BIS',
      codigo: 118,
      id: 15,
      uniqueId: '072f623e-9194-4ea4-8ba8-d0a875d7aff6',
    },
    {
      descricao: 'Bisnaga',
      sigla: 'Bg',
      codigo: 24,
      id: 16,
      uniqueId: 'ec9f17b1-9bb6-439a-ac3c-22526030d937',
    },
    {
      descricao: 'Bloco',
      sigla: 'Bl',
      codigo: 41,
      id: 17,
      uniqueId: '18fccc35-0e46-4468-9a58-4b984ba395ac',
    },
    {
      descricao: 'Blocos',
      sigla: 'BLO',
      codigo: 119,
      id: 18,
      uniqueId: '7ae6c887-7302-43a6-9b5f-52d7c536ac0c',
    },
    {
      descricao: 'Bobina',
      sigla: 'BOB',
      codigo: 121,
      id: 19,
      uniqueId: '74d7d850-89a8-4e73-b793-73caaf43a915',
    },
    {
      descricao: 'Bobina',
      sigla: 'bb',
      codigo: 39,
      id: 20,
      uniqueId: '1fbf7cde-aabc-4d4d-a0ea-9dcf3d2058cf',
    },
    {
      descricao: 'Bola',
      sigla: 'BOL',
      codigo: 122,
      id: 21,
      uniqueId: '0c3afbd6-cffa-4964-9708-ce167c54701a',
    },
    {
      descricao: 'Bolsa',
      sigla: 'BLS',
      codigo: 120,
      id: 22,
      uniqueId: '5c2d3cde-bf27-485f-87c5-f3ae06d58bb1',
    },
    {
      descricao: 'Bolsa',
      sigla: 'Bs',
      codigo: 52,
      id: 23,
      uniqueId: 'd0c2ba2f-b02e-4608-8fa7-9d70d3b7fa5a',
    },
    {
      descricao: 'Bombona',
      sigla: 'BOM',
      codigo: 123,
      id: 24,
      uniqueId: 'd3a49893-6568-4ab3-a83d-2e3d6f5dc259',
    },
    {
      descricao: 'Botijao',
      sigla: 'BOT',
      codigo: 124,
      id: 25,
      uniqueId: 'ed74f670-3675-447b-9f31-6c720f4ceb51',
    },
    {
      descricao: 'Caderno',
      sigla: 'CAD',
      codigo: 126,
      id: 26,
      uniqueId: '406ac4b2-2ab0-43b1-a459-2d3972c5aa7c',
    },
    {
      descricao: 'Caixa',
      sigla: 'Cx',
      codigo: 6,
      id: 27,
      uniqueId: 'c89745d5-c101-4aa9-bfc3-4ffa128869ea',
    },
    {
      descricao: 'Caneta',
      sigla: 'CN',
      codigo: 51,
      id: 28,
      uniqueId: 'f674fcf8-02ef-48fe-825e-c4f786045b46',
    },
    {
      descricao: 'Caneta',
      sigla: 'CAN',
      codigo: 127,
      id: 29,
      uniqueId: 'f5835f59-d556-4f43-8bc6-3ff66bd6c0b5',
    },
    {
      descricao: 'Capsula',
      sigla: 'CAP',
      codigo: 128,
      id: 30,
      uniqueId: '6067727c-cebf-48f2-832a-15be1f087c12',
    },
    {
      descricao: 'Capsula',
      sigla: 'Cp',
      codigo: 25,
      id: 31,
      uniqueId: 'd6b4f934-5212-4f69-bfe2-b1fe44a98710',
    },
    {
      descricao: 'Carga',
      sigla: 'CGA',
      codigo: 131,
      id: 32,
      uniqueId: '24068674-eb0d-4ef8-ad8a-d293e98601bd',
    },
    {
      descricao: 'Carpule',
      sigla: 'CRL',
      codigo: 141,
      id: 33,
      uniqueId: 'a4f70f5b-976f-4c43-b9c9-1d11fbd22e8c',
    },
    {
      descricao: 'Carretel',
      sigla: 'CRL',
      codigo: 140,
      id: 34,
      uniqueId: '3a2de6bf-39fb-4e57-a4e1-37fce6464947',
    },
    {
      descricao: 'Cartao',
      sigla: 'CAR',
      codigo: 129,
      id: 35,
      uniqueId: 'a0e07abf-6e61-4c0a-b2aa-a466b95e1ca9',
    },
    {
      descricao: 'Carteira',
      sigla: 'CRT',
      codigo: 144,
      id: 36,
      uniqueId: '7299d7b1-ed86-431e-98e3-47fa988834af',
    },
    {
      descricao: 'Cartela',
      sigla: 'Ctl',
      codigo: 9,
      id: 37,
      uniqueId: 'dedc33a6-7cc7-48f7-9025-8e80d6787909',
    },
    {
      descricao: 'Cent.Cubic',
      sigla: 'CM3',
      codigo: 135,
      id: 38,
      uniqueId: '1c3b6711-73ed-480a-96cd-c45ec18570d4',
    },
    {
      descricao: 'Centigrama',
      sigla: 'CG',
      codigo: 85,
      id: 40,
      uniqueId: 'eb39af3d-03f3-4abc-bad9-e06ff892eba9',
    },
    {
      descricao: 'Centímetro',
      sigla: 'CM',
      codigo: 65,
      id: 41,
      uniqueId: 'bd56aa0a-6374-46b5-ae17-c3c717814971',
    },
    {
      descricao: 'centímetroporcoluna',
      sigla: 'CMC',
      codigo: 218,
      id: 42,
      uniqueId: '1889da81-5147-4439-a7ba-d7c0fbdff10e',
    },
    {
      descricao: 'Cento',
      sigla: 'ct',
      codigo: 76,
      id: 43,
      uniqueId: 'b6360c2a-7921-4125-9eff-e0e9b0fbeca8',
    },
    {
      descricao: 'Cento',
      sigla: 'CEN',
      codigo: 130,
      id: 44,
      uniqueId: '16ed1079-c6ce-432b-9ce2-0e3d4cdd1ea0',
    },
    {
      descricao: 'Cent.Quadr',
      sigla: 'CM2',
      codigo: 134,
      id: 39,
      uniqueId: '834ff24b-f9b0-410b-a60b-eaf35452fb9c',
    },
    {
      descricao: 'Centrimetro',
      sigla: 'CMS',
      codigo: 136,
      id: 45,
      uniqueId: '107faff1-9b92-4d7b-ba17-f50939d705c4',
    },
    {
      descricao: 'Cilindro',
      sigla: 'CIL',
      codigo: 132,
      id: 46,
      uniqueId: '751069d8-ee19-4811-8b19-b4bb08e9b1d3',
    },
    {
      descricao: 'Cinta',
      sigla: 'CIN',
      codigo: 133,
      id: 47,
      uniqueId: '49d21814-bd59-4483-83b8-209024d47ff1',
    },
    {
      descricao: 'Colecao',
      sigla: 'COL',
      codigo: 137,
      id: 48,
      uniqueId: '0fc97e50-0c65-4cb7-b54e-56bd4865b630',
    },
    {
      descricao: 'Comprimido',
      sigla: 'CP',
      codigo: 27,
      id: 50,
      uniqueId: '6fed9070-b9a5-4bcb-8f7b-791d962cc240',
    },
    {
      descricao: 'Comprimido',
      sigla: 'COM',
      codigo: 138,
      id: 49,
      uniqueId: '2c71d441-b420-4f60-9f69-5701da1590ab',
    },
    {
      descricao: 'Conjunto',
      sigla: 'Cj',
      codigo: 35,
      id: 51,
      uniqueId: 'd85a9407-29b4-4b80-b861-350c8b8252e5',
    },
    {
      descricao: 'Cópia',
      sigla: 'Cpi',
      codigo: 225,
      id: 223,
      uniqueId: '39dea467-9c1c-4599-9a37-d04efaeae29e',
    },
    {
      descricao: 'Copo',
      sigla: 'COP',
      codigo: 139,
      id: 52,
      uniqueId: '5c26add7-c648-4e40-880d-e1cb74cd1a6a',
    },
    {
      descricao: 'CustoHorárioProdutivo',
      sigla: 'CHP',
      codigo: 210,
      id: 53,
      uniqueId: 'a376f153-f8d7-498d-b464-63b674341624',
    },
    {
      descricao: 'Decagrama',
      sigla: 'DAG',
      codigo: 145,
      id: 56,
      uniqueId: '121fa779-f5a7-4c3f-8f10-74a3cb922697',
    },
    {
      descricao: 'Decalitro',
      sigla: 'DAL',
      codigo: 146,
      id: 57,
      uniqueId: 'b186a89a-0195-424b-9b76-ca5bfc5afe24',
    },
    {
      descricao: 'Decametro',
      sigla: 'DAM',
      codigo: 147,
      id: 58,
      uniqueId: '71538229-59af-4686-8564-83cf511aedc7',
    },
    {
      descricao: 'Dec.Cubico',
      sigla: 'DC3',
      codigo: 149,
      id: 54,
      uniqueId: '23a1e11f-bb0a-4636-b5ee-aa9976f61bfd',
    },
    {
      descricao: 'Decigrama',
      sigla: 'DEG',
      codigo: 150,
      id: 59,
      uniqueId: 'ed94266a-0db1-4ab9-93e1-0016ac68a0be',
    },
    {
      descricao: 'Decilitro',
      sigla: 'DEL',
      codigo: 151,
      id: 60,
      uniqueId: '8759bd6b-57a9-40b1-9a08-9db57829f3f1',
    },
    {
      descricao: 'Decimetro',
      sigla: 'DEM',
      codigo: 152,
      id: 61,
      uniqueId: '7568d6cc-8cf1-4801-a62a-fd136fc59515',
    },
    {
      descricao: 'decímetrocúbicoeolitro',
      sigla: 'DM3',
      codigo: 209,
      id: 62,
      uniqueId: 'e3f32fac-9670-4549-b868-dcccc6b9fe1c',
    },
    {
      descricao: 'DEC.Quadra',
      sigla: 'DC2',
      codigo: 148,
      id: 55,
      uniqueId: 'fa02b6c1-a3ba-4da0-9cb9-a317bc079261',
    },
    {
      descricao: 'Dezena',
      sigla: 'Dz',
      codigo: 12,
      id: 64,
      uniqueId: '93ae5566-de58-47bb-955a-abb0649ba765',
    },
    {
      descricao: 'Dezena',
      sigla: 'DEZ',
      codigo: 153,
      id: 63,
      uniqueId: '11f71260-f21a-43b6-bfc3-ac4d34a2a0ab',
    },
    {
      descricao: 'Dia',
      sigla: 'DI',
      codigo: 70,
      id: 65,
      uniqueId: '347ffd69-6b74-48f3-afd5-184c772be8a3',
    },
    {
      descricao: 'Diaria',
      sigla: 'DI',
      codigo: 69,
      id: 66,
      uniqueId: 'd04b10a1-19c5-4f4e-8c42-4ea348ae7ff5',
    },
    {
      descricao: 'dose',
      sigla: 'dos',
      codigo: 216,
      id: 67,
      uniqueId: '9f6fd2bd-90e3-4e67-8c7b-c0a9ac9f5e7a',
    },
    {
      descricao: 'Dragea',
      sigla: 'DG',
      codigo: 86,
      id: 68,
      uniqueId: '6c185901-0359-4841-ac13-1fdc85dd7ff9',
    },
    {
      descricao: 'Drágea',
      sigla: 'Dr',
      codigo: 30,
      id: 69,
      uniqueId: 'b053d624-0c0a-4101-9f59-b9d7a0ba5641',
    },
    {
      descricao: 'Duzia',
      sigla: 'Dz',
      codigo: 33,
      id: 70,
      uniqueId: 'ebf9a515-6f3b-4c65-ab69-1f81977f919c',
    },
    {
      descricao: 'Embalagem',
      sigla: 'EB',
      codigo: 49,
      id: 71,
      uniqueId: '8ce950a3-9457-4874-9922-cf3d6e006387',
    },
    {
      descricao: 'Embgcom100unidades',
      sigla: 'Emb',
      codigo: 81,
      id: 72,
      uniqueId: '7c9fa236-698f-4819-91d2-3f56152d1fef',
    },
    {
      descricao: 'Engradado',
      sigla: 'ENG',
      codigo: 154,
      id: 73,
      uniqueId: 'b5bc8fb0-8c34-4d63-9d76-a44477ec4c74',
    },
    {
      descricao: 'Envelope',
      sigla: 'Ev',
      codigo: 23,
      id: 75,
      uniqueId: 'a54f5edf-f33e-4952-8bc8-c5e1ca057ecf',
    },
    {
      descricao: 'Envelope',
      sigla: 'ENV',
      codigo: 155,
      id: 74,
      uniqueId: 'cb1602bb-db89-4f0c-b737-9c01e424a5da',
    },
    {
      descricao: 'Estojo',
      sigla: 'EST',
      codigo: 156,
      id: 77,
      uniqueId: '89f7b1f3-7c49-45d0-bc36-fc117e3dfa51',
    },
    {
      descricao: 'Estojo',
      sigla: 'EST',
      codigo: 72,
      id: 76,
      uniqueId: '7a029aa5-156d-4aab-9b81-32e61a9dd1fb',
    },
    {
      descricao: 'Estola',
      sigla: 'ETL',
      codigo: 157,
      id: 78,
      uniqueId: '82582520-6842-450d-846d-92dd2d346533',
    },
    {
      descricao: 'Evento',
      sigla: 'EV',
      codigo: 82,
      id: 79,
      uniqueId: '82f41a4d-2afc-4199-a1ca-b1d98040fb97',
    },
    {
      descricao: 'Fardo',
      sigla: 'Far',
      codigo: 18,
      id: 80,
      uniqueId: '316582dd-ef29-43f3-8846-bfb15c95a59b',
    },
    {
      descricao: 'Fardo',
      sigla: 'FDO',
      codigo: 159,
      id: 81,
      uniqueId: '9681ae8b-ee6a-46b8-8e83-802a9657ba95',
    },
    {
      descricao: 'Ficha',
      sigla: 'FCH',
      codigo: 158,
      id: 82,
      uniqueId: 'c36097df-77bd-4ca9-8f2d-d9b114e86a23',
    },
    {
      descricao: 'Fita',
      sigla: 'FIT',
      codigo: 160,
      id: 83,
      uniqueId: '2f6f3d9b-4dd3-4956-b01f-369273d6588f',
    },
    {
      descricao: 'Flaconete',
      sigla: 'Fla',
      codigo: 48,
      id: 85,
      uniqueId: '8b79bf8b-6fe2-48b4-8829-d827f06409ef',
    },
    {
      descricao: 'Flaconete',
      sigla: 'FLA',
      codigo: 161,
      id: 84,
      uniqueId: 'c5ca30dd-e16f-4892-a2c4-24cac3d90929',
    },
    {
      descricao: 'Folha',
      sigla: 'FL',
      codigo: 46,
      id: 86,
      uniqueId: 'f09196c4-02b0-44a5-89ab-c38bcdb9ba10',
    },
    {
      descricao: 'Folhas',
      sigla: 'FLS',
      codigo: 162,
      id: 87,
      uniqueId: '9624a6d3-8aa8-40d7-be08-8cf9f393f315',
    },
    {
      descricao: 'Fração',
      sigla: 'FC',
      codigo: 67,
      id: 88,
      uniqueId: '9477a709-e76e-42c5-b3b5-20eecd22e904',
    },
    {
      descricao: 'Frasco',
      sigla: 'Fr',
      codigo: 14,
      id: 89,
      uniqueId: 'e3b8d57d-1495-4e8b-ba98-d39ecc596932',
    },
    {
      descricao: 'Frasco/Ampola',
      sigla: 'F/A',
      codigo: 36,
      id: 90,
      uniqueId: '06220fcd-5a8c-4de9-ad99-57a0f3910b94',
    },
    {
      descricao: 'Frascos',
      sigla: 'FRS',
      codigo: 163,
      id: 91,
      uniqueId: '2369998d-1275-49cc-9dc0-bb631aa02e3b',
    },
    {
      descricao: 'GALAO',
      sigla: 'GAL',
      codigo: 164,
      id: 92,
      uniqueId: '80ba5002-612d-4f85-bc01-39f691d74360',
    },
    {
      descricao: 'Galão',
      sigla: 'Gal',
      codigo: 15,
      id: 93,
      uniqueId: '07934d15-5206-4dfa-8994-5c6deb952ad5',
    },
    {
      descricao: 'Garrafa',
      sigla: 'Gf',
      codigo: 59,
      id: 94,
      uniqueId: '29ba1cb5-64fa-4111-a3d8-86b8ad7b6382',
    },
    {
      descricao: 'GARRAFAO',
      sigla: 'GFO',
      codigo: 165,
      id: 95,
      uniqueId: '0361a2b0-aad6-44ea-a812-91ec08d53ef3',
    },
    {
      descricao: 'Grama',
      sigla: 'GR',
      codigo: 45,
      id: 96,
      uniqueId: 'cf11316f-fcaa-45a5-a8a5-11fe3b932d5e',
    },
    {
      descricao: 'Gramas',
      sigla: 'GRS',
      codigo: 167,
      id: 97,
      uniqueId: '0ac5dcb7-acae-47b2-8eb1-a0ae3440b764',
    },
    {
      descricao: 'Grosa',
      sigla: 'GRO',
      codigo: 166,
      id: 98,
      uniqueId: '50fb00d7-5cd2-4c77-ba9a-fb12d3bf7c30',
    },
    {
      descricao: 'Hectare',
      sigla: 'HC',
      codigo: 68,
      id: 99,
      uniqueId: 'bafc7e81-0f30-498b-a6ae-22425cc0f16d',
    },
    {
      descricao: 'Hectolitro',
      sigla: 'HL',
      codigo: 88,
      id: 100,
      uniqueId: '6d19bfd4-b3d6-44d6-8612-7a320e080673',
    },
    {
      descricao: 'Hectometro',
      sigla: 'HM',
      codigo: 89,
      id: 101,
      uniqueId: 'abc76c62-728a-4847-bd1c-d5194b570228',
    },
    {
      descricao: 'Hora',
      sigla: 'HOR',
      codigo: 168,
      id: 103,
      uniqueId: '47eec03f-8823-4892-8945-c41e49842fcf',
    },
    {
      descricao: 'Hora',
      sigla: 'HR',
      codigo: 56,
      id: 102,
      uniqueId: '791209eb-c78e-4609-a7cc-39e667b5db05',
    },
    {
      descricao: 'HoraMaquina',
      sigla: 'HM',
      codigo: 74,
      id: 104,
      uniqueId: '6fc2c3c3-3a83-4950-9c15-fb018141b54d',
    },
    {
      descricao: 'Injetavel',
      sigla: 'Inj',
      codigo: 38,
      id: 105,
      uniqueId: '5c67ceb8-99fe-42b7-878b-4a7d6d2dbcba',
    },
    {
      descricao: 'Jarda',
      sigla: 'JAR',
      codigo: 169,
      id: 106,
      uniqueId: 'db413972-e6b7-4ea5-bd55-8060b6dd0139',
    },
    {
      descricao: 'JardaCubi',
      sigla: 'JD3',
      codigo: 171,
      id: 107,
      uniqueId: '940e46f8-8063-41a9-8bca-8d948748ccca',
    },
    {
      descricao: 'JardaQuad',
      sigla: 'JD2',
      codigo: 170,
      id: 108,
      uniqueId: '7480d1c7-e7bc-4fae-8921-794efb4d7659',
    },
    {
      descricao: 'Jogo',
      sigla: 'JG',
      codigo: 90,
      id: 109,
      uniqueId: '7a884dbe-2d0a-4641-be9f-e2002ce9eb3e',
    },
    {
      descricao: 'jogos',
      sigla: 'jg',
      codigo: 22,
      id: 110,
      uniqueId: 'b36cd953-60d6-4c55-88f3-7e7d27f6fd7b',
    },
    {
      descricao: 'Kilograma',
      sigla: 'KG',
      codigo: 91,
      id: 111,
      uniqueId: 'e99c280f-dcd6-4a4f-a4ea-cb3b5045b516',
    },
    {
      descricao: 'Kilolitro',
      sigla: 'KL',
      codigo: 92,
      id: 112,
      uniqueId: '513065ec-b3d8-47ca-8a2a-b82ae763eab1',
    },
    {
      descricao: 'Kilometro',
      sigla: 'KM',
      codigo: 93,
      id: 113,
      uniqueId: 'e547b386-a338-4d10-804a-1e2b3c105427',
    },
    {
      descricao: 'Kit',
      sigla: 'kt',
      codigo: 21,
      id: 115,
      uniqueId: 'a67945fa-393a-4a20-9783-e4d7780361a2',
    },
    {
      descricao: 'KIT',
      sigla: 'KIT',
      codigo: 172,
      id: 114,
      uniqueId: 'ff3c4a2e-a844-4c13-90dc-3fb6a4bca2eb',
    },
    {
      descricao: 'KMRodado',
      sigla: 'Km',
      codigo: 75,
      id: 116,
      uniqueId: '0d7995e7-e411-4ba2-8034-a6a0f68db602',
    },
    {
      descricao: 'Lata',
      sigla: 'Lt',
      codigo: 28,
      id: 117,
      uniqueId: '36342ed8-a506-4ff6-acc9-cd8b7c449352',
    },
    {
      descricao: 'LATAS',
      sigla: 'LTA',
      codigo: 174,
      id: 118,
      uniqueId: '812adc53-d2e3-4c35-8ad5-679ac557ee4a',
    },
    {
      descricao: 'Libra',
      sigla: 'LB',
      codigo: 94,
      id: 119,
      uniqueId: '60b5935f-7257-482c-b6e8-c1bd009be0b1',
    },
    {
      descricao: 'Lingote',
      sigla: 'LIN',
      codigo: 173,
      id: 120,
      uniqueId: '8fc7d5c8-ede3-4461-b35f-f073fc76f49f',
    },
    {
      descricao: 'Litro',
      sigla: 'L',
      codigo: 1,
      id: 122,
      uniqueId: '883643f0-a6bc-4b80-a8d7-30b54b4e8518',
    },
    {
      descricao: 'Litro',
      sigla: 'LT',
      codigo: 95,
      id: 121,
      uniqueId: 'f126326b-aa10-432b-80a1-e19d1ed60296',
    },
    {
      descricao: 'Livro',
      sigla: 'LVR',
      codigo: 175,
      id: 123,
      uniqueId: 'b13219fe-a04e-49f6-ba00-61f1bb6c0006',
    },
    {
      descricao: 'Lugar/Dia',
      sigla: 'LgD',
      codigo: 230,
      id: 217,
      uniqueId: 'dfd7ecb5-bbe3-463f-8cf3-ea27d5fa8b9d',
    },
    {
      descricao: 'Maço',
      sigla: 'MC',
      codigo: 44,
      id: 124,
      uniqueId: '10e696e2-6d70-4969-996c-14daee4b3fe5',
    },
    {
      descricao: 'Macos',
      sigla: 'MCS',
      codigo: 176,
      id: 125,
      uniqueId: '9ac03b33-dec4-4a38-a784-53bda97fc6e5',
    },
    {
      descricao: 'Meada',
      sigla: 'ME',
      codigo: 98,
      id: 126,
      uniqueId: '8649d899-5632-4b8d-bbff-682b2d495585',
    },
    {
      descricao: 'Meadas',
      sigla: 'MEA',
      codigo: 177,
      id: 127,
      uniqueId: 'fe266d6a-c7f6-4dd2-ab18-e060ade6bc6b',
    },
    {
      descricao: 'Megabyte',
      sigla: 'MB',
      codigo: 234,
      id: 224,
      uniqueId: '2f5fdf1b-4f36-4839-a79f-3bd7466ed189',
    },
    {
      descricao: 'Megawatt-Hora',
      sigla: 'Mwh',
      codigo: 223,
      id: 221,
      uniqueId: 'd532c38f-9d60-438e-ab6e-1021e222acdb',
    },
    {
      descricao: 'Mês',
      sigla: 'Mes',
      codigo: 34,
      id: 128,
      uniqueId: '259e4ff9-25a7-4794-9bde-d38d8dd2bfee',
    },
    {
      descricao: 'Metro',
      sigla: 'M',
      codigo: 2,
      id: 129,
      uniqueId: 'd2035615-b8ba-42e0-8c1a-ac0e7104daf1',
    },
    {
      descricao: 'MetroCorrido',
      sigla: 'MC',
      codigo: 66,
      id: 130,
      uniqueId: '39fb60e8-27af-4c60-af19-27dc005a8146',
    },
    {
      descricao: 'MetroCub',
      sigla: 'M3',
      codigo: 97,
      id: 131,
      uniqueId: 'e3a8fa1e-5106-48c3-8316-b66cbe06e21b',
    },
    {
      descricao: 'MetroCubico',
      sigla: 'M3',
      codigo: 73,
      id: 132,
      uniqueId: 'a8ded8ec-72ee-4758-b3ca-961584a9ceef',
    },
    {
      descricao: 'MetroCúbico',
      sigla: 'M3',
      codigo: 53,
      id: 133,
      uniqueId: '3212dd94-dee2-4f68-8194-af5472591e23',
    },
    {
      descricao: 'MetroCubicoporKilometro',
      sigla: 'MKM',
      codigo: 211,
      id: 134,
      uniqueId: '9107cd3a-2f28-41e9-a906-2e907e2309af',
    },
    {
      descricao: 'MetroCubicoporMês',
      sigla: 'MMS',
      codigo: 212,
      id: 135,
      uniqueId: '68c80713-5ad9-4584-b29d-58897780836c',
    },
    {
      descricao: 'MetroLinear',
      sigla: 'ML',
      codigo: 63,
      id: 136,
      uniqueId: '9e1e9199-1e58-4f87-8772-37be7bdf1055',
    },
    {
      descricao: 'Metro Linear/Dia',
      sigla: 'MLD',
      codigo: 230,
      id: 219,
      uniqueId: '90e4b9d9-8ff7-4b0b-b3ce-9709d938ed92',
    },
    {
      descricao: 'MetroQua',
      sigla: 'M2',
      codigo: 96,
      id: 137,
      uniqueId: 'd68ea7e4-593b-45da-ac45-0ed2ce19241d',
    },
    {
      descricao: 'MetroQuadrado',
      sigla: 'Mt2',
      codigo: 47,
      id: 138,
      uniqueId: 'fb715b9b-529a-4014-8d5a-48108fd7bbd2',
    },
    {
      descricao: 'Metro Quadrado/Dia',
      sigla: 'M2D',
      codigo: 231,
      id: 218,
      uniqueId: '40cd036b-c9a6-4deb-9edb-a6484bcd2f30',
    },
    {
      descricao: 'metrosestéreos',
      sigla: 'ME',
      codigo: 61,
      id: 139,
      uniqueId: '49c03c5e-58f6-452c-af02-6e32f6374f21',
    },
    {
      descricao: 'milheiro',
      sigla: 'ml',
      codigo: 20,
      id: 141,
      uniqueId: '13c99396-9a72-4b99-bc3d-7a85a32feb43',
    },
    {
      descricao: 'Milheiro',
      sigla: 'MLH',
      codigo: 178,
      id: 140,
      uniqueId: '1f4fbedf-57ca-4ca5-928b-cb2a65824be6',
    },
    {
      descricao: 'Miligrama',
      sigla: 'MG',
      codigo: 99,
      id: 142,
      uniqueId: '878f7a98-a865-4ae2-ac74-43181e421227',
    },
    {
      descricao: 'Mililitro',
      sigla: 'Ml',
      codigo: 58,
      id: 143,
      uniqueId: 'a8b89c26-19c2-4bc2-bd2e-deb423abe1e9',
    },
    {
      descricao: 'Mililitros',
      sigla: 'ML',
      codigo: 100,
      id: 144,
      uniqueId: '02eacf72-3a62-4402-86ed-714f5e0d678d',
    },
    {
      descricao: 'Milimetros',
      sigla: 'MM',
      codigo: 101,
      id: 145,
      uniqueId: 'f73438da-9ce1-467f-857f-dfc6ebc2ccbb',
    },
    {
      descricao: 'Minuto',
      sigla: 'Min',
      codigo: 83,
      id: 146,
      uniqueId: '03986823-0e14-45fa-9e25-33138b7179b9',
    },
    {
      descricao: 'Molho',
      sigla: 'MO',
      codigo: 102,
      id: 147,
      uniqueId: 'e143f269-bc73-489c-a1dd-826ae7d0f183',
    },
    {
      descricao: 'MT.LINEAR',
      sigla: 'MTL',
      codigo: 179,
      id: 148,
      uniqueId: '6678d9d8-7681-4c9f-89ce-32b510a62270',
    },
    {
      descricao: 'Muda',
      sigla: 'Mud',
      codigo: 79,
      id: 149,
      uniqueId: '3a3d173a-40cc-4808-b343-44526580a731',
    },
    {
      descricao: 'Novelo',
      sigla: 'NOV',
      codigo: 180,
      id: 150,
      uniqueId: '2b4e4e0b-a13f-4303-92b4-271bd942e16f',
    },
    {
      descricao: 'Onca',
      sigla: 'ON',
      codigo: 103,
      id: 151,
      uniqueId: '0d8d44dd-522a-4784-ab1c-36b3dbcd9dd4',
    },
    {
      descricao: 'Ovulo',
      sigla: 'OV',
      codigo: 104,
      id: 152,
      uniqueId: '91e0d714-d2b9-4376-a4e8-34a30709550c',
    },
    {
      descricao: 'Pacote',
      sigla: 'Pac',
      codigo: 17,
      id: 153,
      uniqueId: '68bfb2c8-44c7-4402-95da-0c2d7824e8c6',
    },
    {
      descricao: 'PACOTES',
      sigla: 'PCT',
      codigo: 183,
      id: 154,
      uniqueId: '3b84d13a-a9f7-4d71-aca0-3db67a104b5c',
    },
    {
      descricao: 'Par',
      sigla: 'Par',
      codigo: 13,
      id: 155,
      uniqueId: '2beb6f93-9100-4f82-ab17-02dfa05b8564',
    },
    {
      descricao: 'PARES',
      sigla: 'PAR',
      codigo: 181,
      id: 156,
      uniqueId: 'ef468c8a-0090-42ad-a4e3-24c0f5557770',
    },
    {
      descricao: 'Pastilha',
      sigla: 'PAS',
      codigo: 182,
      id: 157,
      uniqueId: 'f6298d5a-b0f2-4434-9591-8176799eb1c3',
    },
    {
      descricao: 'PE',
      sigla: 'PE',
      codigo: 106,
      id: 158,
      uniqueId: 'a7230689-d4bb-40cb-b6d8-fe0992540618',
    },
    {
      descricao: 'Peca',
      sigla: 'PC',
      codigo: 105,
      id: 161,
      uniqueId: 'a4850f6e-5036-44da-a64b-00e8835472e3',
    },
    {
      descricao: 'Peça',
      sigla: 'Pç',
      codigo: 7,
      id: 162,
      uniqueId: '85fb7f5c-066c-4f3b-b2ea-dd120f1cf689',
    },
    {
      descricao: 'PECUBICO',
      sigla: 'PE3',
      codigo: 185,
      id: 159,
      uniqueId: 'd1ab816c-6e3b-4e40-9259-ee988e293822',
    },
    {
      descricao: 'PEQUADRAD',
      sigla: 'PE2',
      codigo: 184,
      id: 160,
      uniqueId: '64ece40f-2ad4-41be-bf6e-1fed01895ef8',
    },
    {
      descricao: 'Percentual',
      sigla: '%',
      codigo: 43,
      id: 163,
      uniqueId: 'b8ee2c93-39e6-413d-8b5e-3faec8e6af77',
    },
    {
      descricao: 'Percurso',
      sigla: 'PRC',
      codigo: 230,
      id: 220,
      uniqueId: '7050877b-adf1-4304-9171-171728c1820b',
    },
    {
      descricao: 'PET',
      sigla: 'PET',
      codigo: 186,
      id: 164,
      uniqueId: '3bc02cc5-06af-4874-8356-bb5bdc5cba0c',
    },
    {
      descricao: 'Pinta',
      sigla: 'PIN',
      codigo: 187,
      id: 165,
      uniqueId: 'e0b76a50-f1a1-4e28-afdf-abb89d754228',
    },
    {
      descricao: 'Placa',
      sigla: 'PLA',
      codigo: 188,
      id: 166,
      uniqueId: '6bf23578-8a98-4418-86d6-7a00d715f712',
    },
    {
      descricao: 'POL.CUBICA',
      sigla: 'PO3',
      codigo: 190,
      id: 167,
      uniqueId: '01fbb7dd-d7c5-46c7-95b4-74d5f89eba3d',
    },
    {
      descricao: 'Polegads',
      sigla: 'POL',
      codigo: 191,
      id: 169,
      uniqueId: '52f0dd72-850d-43f7-a1c2-fef4fd6acd90',
    },
    {
      descricao: 'POL.QUADRA',
      sigla: 'PO2',
      codigo: 189,
      id: 168,
      uniqueId: 'eb66fd24-67f2-4c6f-8e4b-c918ca7312cb',
    },
    {
      descricao: 'Pontos',
      sigla: 'Pts',
      codigo: 78,
      id: 170,
      uniqueId: 'a51af71e-cb68-4405-936f-446c1a6de00b',
    },
    {
      descricao: 'Pote',
      sigla: 'Pt',
      codigo: 26,
      id: 171,
      uniqueId: 'ca96a88a-1d2e-43fb-be94-474b878d31e3',
    },
    {
      descricao: 'Pote',
      sigla: 'POT',
      codigo: 192,
      id: 172,
      uniqueId: '5b37c249-cef1-4217-83c6-7304f669c3d1',
    },
    {
      descricao: 'Projeto',
      sigla: 'pro',
      codigo: 215,
      id: 173,
      uniqueId: '197038f5-2dc4-4058-b613-456d6d0ab9c7',
    },
    {
      descricao: 'Quilograma',
      sigla: 'Kg',
      codigo: 3,
      id: 174,
      uniqueId: 'fcafdf8e-2c1f-4d6f-825f-66ffd9338921',
    },
    {
      descricao: 'Quilometro',
      sigla: 'KM',
      codigo: 54,
      id: 175,
      uniqueId: '354214ba-307c-4a93-be89-7d2cb1d580d9',
    },
    {
      descricao: 'Racao',
      sigla: 'RAC',
      codigo: 193,
      id: 176,
      uniqueId: 'ccb20abf-cab1-4ff4-8d59-3d0a2cba41b0',
    },
    {
      descricao: 'Recarga',
      sigla: 'REC',
      codigo: 222,
      id: 216,
      uniqueId: '232cc207-f657-4395-8a14-d1e00c375071',
    },
    {
      descricao: 'Refil',
      sigla: 'RF',
      codigo: 50,
      id: 178,
      uniqueId: '74b93e4a-95f2-4473-8cd8-b604f1e0d14b',
    },
    {
      descricao: 'REFIL',
      sigla: 'RFL',
      codigo: 194,
      id: 177,
      uniqueId: 'e3c29feb-0b02-40da-b90d-aafc4dae0386',
    },
    {
      descricao: 'Resma',
      sigla: 'RM',
      codigo: 107,
      id: 180,
      uniqueId: '76296b82-0a88-4988-976b-ce53fea0106e',
    },
    {
      descricao: 'Resma',
      sigla: 'Rm',
      codigo: 8,
      id: 179,
      uniqueId: 'ae0c19ea-5203-488c-92ec-10b9d37c3403',
    },
    {
      descricao: 'Rolo',
      sigla: 'Rl',
      codigo: 19,
      id: 182,
      uniqueId: 'ce2e977b-dff6-4b30-bd84-92b6dce9a05c',
    },
    {
      descricao: 'ROLO',
      sigla: 'ROL',
      codigo: 195,
      id: 181,
      uniqueId: 'aec664fd-1f70-4c89-bdee-90afba33f200',
    },
    {
      descricao: 'Saca',
      sigla: 'Sc',
      codigo: 10,
      id: 183,
      uniqueId: 'd06f577d-cf05-461e-b19a-056d5023a33c',
    },
    {
      descricao: 'Sachê',
      sigla: 'Sac',
      codigo: 29,
      id: 184,
      uniqueId: '76f636f4-1d21-4941-82db-29d7059ae587',
    },
    {
      descricao: 'Sachê',
      sigla: 'She',
      codigo: 84,
      id: 185,
      uniqueId: 'a204d84c-f7db-4089-82da-eedaacbd3b67',
    },
    {
      descricao: 'SACHES',
      sigla: 'SHE',
      codigo: 197,
      id: 186,
      uniqueId: '46b33fef-2697-495b-90a3-c017f3662ed0',
    },
    {
      descricao: 'SACOS',
      sigla: 'SCS',
      codigo: 196,
      id: 187,
      uniqueId: '0e1927a6-c03c-4935-a97b-1025561976f7',
    },
    {
      descricao: 'SACOS',
      sigla: 'SC',
      codigo: 37,
      id: 188,
      uniqueId: 'a9798d0a-6adc-478b-8e4f-33baf80855f7',
    },
    {
      descricao: 'Semana',
      sigla: 'S',
      codigo: 80,
      id: 189,
      uniqueId: '38f68f46-6e4a-422a-a5d9-8638542be39e',
    },
    {
      descricao: 'Seringa',
      sigla: 'Sg',
      codigo: 42,
      id: 191,
      uniqueId: '4c238dff-cd34-4774-9056-123b43dab3d6',
    },
    {
      descricao: 'SERINGA',
      sigla: 'SRG',
      codigo: 198,
      id: 190,
      uniqueId: '00445261-cca9-4382-95ca-6378286c007e',
    },
    {
      descricao: 'Serviço',
      sigla: 'Ser',
      codigo: 64,
      id: 192,
      uniqueId: '9a32d77e-d87c-486f-ab39-bd58d1e9bf31',
    },
    {
      descricao: 'SUPOSITORI',
      sigla: 'SUP',
      codigo: 199,
      id: 193,
      uniqueId: '75cea4e3-0d7f-49dc-b21d-c54f8f0e66ce',
    },
    {
      descricao: 'TABLETE',
      sigla: 'TBL',
      codigo: 201,
      id: 194,
      uniqueId: '3bfd6a10-08fd-4e97-b202-9c4f2fb6dee6',
    },
    {
      descricao: 'TALAO',
      sigla: 'TAL',
      codigo: 200,
      id: 195,
      uniqueId: '0c41e25f-b633-4d05-abd2-714821c825a2',
    },
    {
      descricao: 'Tambor',
      sigla: 'Tb',
      codigo: 55,
      id: 196,
      uniqueId: 'ebebdedf-324f-4931-9d47-4d47b9988705',
    },
    {
      descricao: 'Tambor',
      sigla: 'TB',
      codigo: 108,
      id: 197,
      uniqueId: 'dadc2684-1bee-4a5f-a4d2-5e6cf25a08bf',
    },
    {
      descricao: 'Taxa',
      sigla: 'Tax',
      codigo: 214,
      id: 198,
      uniqueId: '48b65630-3219-41db-ab9b-5482dcd5106a',
    },
    {
      descricao: 'Testes',
      sigla: 'Tes',
      codigo: 40,
      id: 199,
      uniqueId: 'df94885d-bb94-4af4-82cf-3e410f3f97e4',
    },
    {
      descricao: 'Toco',
      sigla: 'TO',
      codigo: 109,
      id: 200,
      uniqueId: 'd04a319f-8ade-4fe5-968a-820a7fe440bd',
    },
    {
      descricao: 'TONEL',
      sigla: 'TOL',
      codigo: 202,
      id: 201,
      uniqueId: 'bada374a-0854-4976-bbed-b52276a1d6d1',
    },
    {
      descricao: 'Tonelada',
      sigla: 'T',
      codigo: 4,
      id: 202,
      uniqueId: 'd520f766-a727-4713-8e26-451d29d3d330',
    },
    {
      descricao: 'TORRAO',
      sigla: 'TOR',
      codigo: 203,
      id: 203,
      uniqueId: 'cfcf6240-8a1e-4638-99bc-26171a6574f1',
    },
    {
      descricao: 'Tubete',
      sigla: 'Tb',
      codigo: 32,
      id: 204,
      uniqueId: 'fc6255b5-b125-4eca-a675-4e1bf2403457',
    },
    {
      descricao: 'Tubete',
      sigla: 'TU',
      codigo: 110,
      id: 205,
      uniqueId: 'f19db622-1c37-479c-ba5b-dfeb7eee2871',
    },
    {
      descricao: 'Tubo',
      sigla: 'Tub',
      codigo: 16,
      id: 207,
      uniqueId: 'bdef6aad-0559-46ad-98db-225408308179',
    },
    {
      descricao: 'TUBO',
      sigla: 'TUB',
      codigo: 204,
      id: 206,
      uniqueId: '841db38e-3602-42c5-b6b0-f6b7dfbb95d8',
    },
    {
      descricao: 'TURNO',
      sigla: 'TUR',
      codigo: 205,
      id: 208,
      uniqueId: '8f9461bb-1624-4d72-a052-759a58b558f0',
    },
    {
      descricao: 'Unidade',
      sigla: 'Un',
      codigo: 5,
      id: 209,
      uniqueId: '5fed3694-9349-4e84-bc54-cbf860721c50',
    },
    {
      descricao: 'Unidade x Mês',
      sigla: 'UnM',
      codigo: 224,
      id: 222,
      uniqueId: '3694382f-841c-4588-8b02-9e27071e56d6',
    },
    {
      descricao: 'VEICULO',
      sigla: 'VEC',
      codigo: 213,
      id: 210,
      uniqueId: '09c49822-8019-43a9-b225-fdb067bb9e0b',
    },
    {
      descricao: 'Vidro',
      sigla: 'Vdr',
      codigo: 57,
      id: 212,
      uniqueId: 'af98f94f-d1c1-4d1f-a24f-bf4462c1590d',
    },
    {
      descricao: 'VIDRO',
      sigla: 'VDR',
      codigo: 206,
      id: 211,
      uniqueId: 'd8d5548b-5af4-4434-b8d6-c544172d1b05',
    },
    {
      descricao: 'VIDROS',
      sigla: 'VDS',
      codigo: 207,
      id: 213,
      uniqueId: '0c7f3f36-d397-4e55-879b-054a00822fe0',
    },
    {
      descricao: 'VLGlobal',
      sigla: 'GL',
      codigo: 87,
      id: 214,
      uniqueId: 'c278ec7b-4e2f-42ab-88e1-d56d02a3365f',
    },
    {
      descricao: 'VOLUME',
      sigla: 'VOL',
      codigo: 208,
      id: 215,
      uniqueId: '2c4efb8f-2b48-4363-aa42-5e6ded0335b4',
    },
  ],
  modalidade: [
    {
      faseNegociacao: [
        {
          id: 8,
          name: 'Habilitação',
        },
      ],
      amparoLegal: [
        {
          uniqueId: '023d55e1-c4fd-4b5f-a8cd-7277683183c0',
          descricao: 'Lei 14.133/2021, Art. 28, II (concorrência)',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: true,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 2,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21, Artigo 28, II (concorrência). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '60fc75ab-c859-4b5f-8770-1238e868b11b',
          descricao:
            'Lei 14.133/2021, Art. 28, II (PNCP e Transfere Gov +Brasil)',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 2,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Concorrência Eletrônica programada com base na Lei 14.133/21, Artigo 28, II (concorrência). Será informada e publicada no PNCP - Plataforma Nacional de Contratações Públicas. Também será informada na plataforma Transfere Gov (+Brasil) com base na Lei 14.133/21. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
      ],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 4,
              name: 'Melhor Técnica ou Conteúdo Artístico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 5,
              name: 'Maior Retorno Econômico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 9,
          name: 'Aquisição de Bens Especiais',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 4,
              name: 'Melhor Técnica ou Conteúdo Artístico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 5,
              name: 'Maior Retorno Econômico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 12,
          name: 'Contratação de Serviços Especiais',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 2,
              name: 'Maior Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 5,
              name: 'Maior Retorno Econômico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 3,
          name: 'Concessão',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 4,
              name: 'Melhor Técnica ou Conteúdo Artístico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 5,
              name: 'Maior Retorno Econômico',
            },
          ],
          id: 10,
          name: 'Contratação de Obras e Serviços Comuns e Especiais de Engenharia',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
          ],
          id: 5,
          name: 'Registro de Preços',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 6,
          name: 'Compra (Aquisição)',
        },
      ],
      id: 1,
      name: 'Concorrência',
    },
    {
      faseNegociacao: [
        {
          id: 8,
          name: 'Habilitação',
        },
      ],
      amparoLegal: [],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
          ],
          id: 3,
          name: 'Concessão',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
          ],
          id: 4,
          name: 'Contratação de Obra, Serviços e Compras',
        },
      ],
      id: 2,
      name: 'Diálogo Competitivo',
    },
    {
      faseNegociacao: [
        {
          id: 4,
          name: 'Análise de Propostas',
        },
        {
          id: 5,
          name: 'Disputa – Lances',
        },
        {
          id: 7,
          name: 'Julgamento e Aceitação de Propostas',
        },
        {
          id: 8,
          name: 'Habilitação',
        },
      ],
      amparoLegal: [
        {
          uniqueId: '250b5492-db81-4c61-a46b-ac21d370af36',
          descricao: 'TESTE nova LEI OBS',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'teste@teste.com.br',
          codigoPncp: 123456,
          observacoes: {
            mensagens: [
              {
                tipo: 'Alerta',
                descricao: 'Visualização de alerta',
              },
              {
                tipo: 'Básico',
                descricao: 'mensagem fixada',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: true,
        },
        {
          uniqueId: '91c68d1b-3e65-4521-9abd-6698c0cddd8a',
          descricao: 'Lei 14.133/2021, Art. 28, I - (pregão eletrônico)',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: true,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 1,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na LeI 14.133/21, Artigo 28, I (pregão eletrônico). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas. Os procedimentos operacionais do Pregão no BBMNET segue as regras estabelecidas no DECRETO Nº 10.024, DE 20 DE SETEMBRO DE 2019. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '9d2deecb-7414-43dd-9c3f-64a54080b4cc',
          descricao: 'Lei 12.462/2011 - RDC- Reg. Dif. de Cont. Públicas',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12462.htm',
          codigoMaisBrasil: 'LEI12462',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '7868504d-bf72-4e97-a340-c07cb78d9ca8',
          descricao: 'Lei 10.520/2002 - Lei do Pregão',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/2002/l10520.htm',
          codigoMaisBrasil: '123',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas. Os procedimentos operacionais do Pregão no BBMNET segue as regras estabelecidas no DECRETO Nº 10.024, DE 20 DE SETEMBRO DE 2019.',
              },
              {
                tipo: 'Alerta',
                descricao:
                  'Você está escolhendo uma lei antiga que perdeu a vigência, você realmente deseja continuar?',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '403d8c3b-7463-46bf-859a-a9b15296d8c2',
          descricao: '8.666/1993',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'INSTRUÇÃO NORMATIVA SEGES/ME Nº 67, DE 8 DE JULHO DE 2021',
          codigoMaisBrasil: 'LEI8666',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao: 'lei inadequada para o pregão ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '045bad5f-e6d0-4521-b32d-329825573626',
          descricao: '11.947/2009',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'a999be8b-d0b6-450a-a9c5-4cd17fc2ac60',
          descricao: '14.133/2021',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '7478dc36-4eaa-4768-bd2f-8c77775ce818',
          descricao:
            'Comitê Olímpico do Brasil - Manual de Compras e Contratos do COB',
          ativo: true,
          pncp: false,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://sistemas.cob.org.br/ProcessoDeCompras/Arquivos/ManualProcessoDeCompras.pdf',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa licitação é regida pelo Manual de Compras e Contratos do Comitê Olímpico do Brasil. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '06f73af8-a5bb-4ddd-8adb-2e0d50be59a4',
          descricao: 'Lei 10.520/2002 - Lei do Pregão',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/2002/l10520.htm',
          codigoMaisBrasil: 'LEI10520',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas. Os procedimentos operacionais do Pregão Eletrônico no BBMNET segue as regras estabelecidas no DECRETO Nº 10.024, DE 20 DE SETEMBRO DE 2019.  ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '53cb9796-4385-4c99-84ae-0043e3cada68',
          descricao: 'Lei 13.303/2016 - Lei das Estatais',
          ativo: true,
          pncp: false,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13303.htm',
          codigoMaisBrasil: 'LEI13303',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas. No Portal BBMNET os procedimentos para a realização do Pregão para Estatais atendem aos requisitos previstos no Artigo 32 e seguintes da Lei 13.303/16',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '6915a90f-7a58-4b4c-9468-cd3825d9e45c',
          descricao: 'Lei 13.303/2016 - Lei das Estatais',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13303.htm',
          codigoMaisBrasil: 'LEI13303',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '1f1c1d29-2563-4cb3-8330-fd0126a3ad77',
          descricao: 'Lei 8.666/1993 - Lei Geral de Licitações',
          ativo: true,
          pncp: false,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/l8666cons.htm',
          codigoMaisBrasil: 'LEI8666',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Esta licitação não será informada no PNCP - Portal Nacional de Contratações Publicas por não estar vinculada a nova Lei 14.133/2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'af88958c-2423-4a71-a55c-784693bc1f0c',
          descricao:
            'Fundação Zerbini - Regulamento de Compras Contratações (Lei 14.133/2021)',
          ativo: true,
          pncp: false,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.fz.org.br/wp-content/uploads/2020/01/regulamento-de-compras-e-contratacoes-fz-1.pdf',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'ESTA LICITAÇÃO OBEDECE INTEGRALMENTE AO DISPOSTO NO REGULAMENTO DE COMPRAS E CONTRATAÇÕES DA FUNDAÇÃO ZERBINI. O REGULAMENTO ESTÁ DISPONÍVEL PARA CONSULTAS NO SITIO DA INTERNET https://www.fz.org.br/wp-content/uploads/2020/01/regulamento-de-compras-e-contratacoes-fz-1.pdf',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '4cfcaaed-6b22-4ef9-ae2e-88d8fdd56228',
          descricao: 'Lei 8.666/1993 - Lei Geral de Licitações',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/l8666cons.htm',
          codigoMaisBrasil: 'LEI8666',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa licitação será publicada com base na Lei 8.666/93 e será levada ao Portal Mais Brasil do Governo Federal, mas NÃO será publicada no PNPC - Portal Nacional de Contratações Publicas exigido apenas para licitações realizadas a Luz da Lei 14.133/2021.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'f9c2c7ba-61b9-4645-a587-7d40eb6134be',
          descricao:
            'Lei 14.133/2021, Art. 28, I (PNCP e Transfere Gov +Brasil)',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 1,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'MUITA ATENÇAO: Informamos que a plataforma Transfere Gov (Mais Brasil) que processa informações de transferencias de recursos da União passou a receber informações de licitações configuradas as luz da nova Lei 14.133/2021. Dessa forma, essa licitação será informada à plataforma Transfere Gov (Mais Brasil)  do Governo Federal na forma da Lei 14.133/21 e também será publicada no PNCP-Portal Nacional de Contratações Publicas na forma da Lei 14.133/21, Art. 28, I (pregão eletrônico). Os procedimentos operacionais do Pregão no BBMNET seguem as regras estabelecidas no DECRETO Nº 10.024, DE 20 DE SETEMBRO DE 2019',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
      ],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 8,
          name: 'Aquisição de Bens Comuns',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
              ],
              id: 2,
              name: 'Maior Preço',
            },
          ],
          id: 11,
          name: 'Contratação de Serviços Comuns',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 13,
          name: 'Registro de Preços para Bens ou Serviços Comuns',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
              ],
              id: 2,
              name: 'Maior Preço',
            },
          ],
          id: 14,
          name: 'Concessão/Alienação',
        },
      ],
      id: 3,
      name: 'Pregão (Setor público)',
    },
    {
      faseNegociacao: [
        {
          id: 5,
          name: 'Disputa – Lances',
        },
      ],
      amparoLegal: [],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
              ],
              id: 7,
              name: 'Maior Lance',
            },
          ],
          id: 7,
          name: 'Venda de Bens Imóveis ou Bens Móveis Inservíveis ou Legalmente Apreendidos',
        },
      ],
      id: 4,
      name: 'Leilão',
    },
    {
      faseNegociacao: [
        {
          id: 5,
          name: 'Disputa – Lances',
        },
        {
          id: 7,
          name: 'Julgamento e Aceitação de Propostas',
        },
      ],
      amparoLegal: [
        {
          uniqueId: '1606d3bc-a3ae-4eda-bc19-39778824fd1b',
          descricao: 'Lei 14.133/2021, Art. 75, I',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: true,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 18,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21 (dispensa). Sendo assim, será informada e publicada na PNCP - Plataforma Nacional de Contratações Publicas. No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'd86ccc0b-7604-4179-814e-dbade0121951',
          descricao: 'Lei 14.133/2021, Art. 75, II',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 19,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21 (dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '68b7133e-6de6-4c41-a917-9742b5473aad',
          descricao: 'Lei 14.133/2021, Art. 75, III, a',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 20,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'c048c0d0-49a4-4f7c-8a4b-210f9c8a437a',
          descricao: 'Lei 14.133/2021, Art. 75, III, b',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 21,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'e547349f-2922-47a7-86c3-1d840b4f9f4e',
          descricao: 'Lei 13.303/2016 - Lei das Estatais',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13303.htm',
          codigoMaisBrasil: 'LEI13303',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '06366a27-abc0-4a57-8c0b-4f4c83ea9586',
          descricao: 'Lei 14.133/2021, Art. 75, IV, e',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 26,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'c6520aa6-6526-4982-adfe-7d022504e79d',
          descricao: 'Lei 14.133/2021, Art. 75, IV, f',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 27,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '26e351af-802c-4560-ad98-1cd737e6a3db',
          descricao: 'Lei 14.133/2021, Art. 75, IV, g',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 28,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '9dd16017-a1eb-4696-b25b-36280b59a28b',
          descricao: 'Lei 14.133/2021, Art. 75, IV, h',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 29,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '6a052591-57f0-4db4-a079-932048f997ce',
          descricao: 'Lei 14.133/2021, Art. 75, IV, a',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 22,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '4e799e32-c1b9-44d6-9f52-cef299036858',
          descricao: '8.666/1993 - Lei Geral de Licitações',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: true,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/l8666cons.htm',
          codigoMaisBrasil: 'LEI8666',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas. No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '55b4334f-ea7a-44db-915d-e2d35b7abb04',
          descricao: 'Lei 14.133/2021, Art. 75, IX',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 39,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '4b27356d-3c30-43a3-8f21-f06c2736dddf',
          descricao: 'Lei 14.133/2021, Art. 75, XII',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 42,
          observacoes: null,
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '657acfc3-e6be-45bb-bdb6-e87c13cdd531',
          descricao: 'Lei 14.133/2021, Art. 75, XIII',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 43,
          observacoes: null,
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'd204e827-8c8f-4055-85c3-0373975375f0',
          descricao: '14.133/2021',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'b2bac527-38b1-46e0-8c21-061c5bd8b770',
          descricao: 'Lei 14.133/2021, Art. 75, IV, i',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 30,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'e6fb4d70-4f26-4ddc-9735-d20bd9255adc',
          descricao: 'Lei 14.133/2021, Art. 75, IV, j',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 31,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '46d08069-a412-4716-9762-cf72445ee473',
          descricao: 'Lei 14.133/2021, Art. 75, IV, k',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 32,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '8052d499-b0a9-4f2c-9d5d-17a55de4aadb',
          descricao: 'Lei 14.133/2021, Art. 75, IV, l',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 33,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '3f5babd6-b80c-49c2-81de-99bda29d67e9',
          descricao: 'Lei 14.133/2021, Art. 75, IV, m',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 34,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'd9230e22-76d9-40c1-b22d-7469cddcb562',
          descricao: 'Lei 14.133/2021, Art. 75, IV, c',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 24,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '2bb1559e-62fc-4bd8-8f88-3e17f0578122',
          descricao: 'Lei 14.133/2021, Art. 75, IV, d',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 25,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'cb06b93a-36c2-4a4e-a25c-d99e59be4630',
          descricao: 'Lei 14.133/2021, Art. 75, X',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 40,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'ed964bc7-bb68-4b49-b1d3-1c0307b6a79e',
          descricao: 'Lei 14.133/2021, Art. 75, XI',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 41,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'a72029b9-74cd-4cfb-9403-4ac73c8d5920',
          descricao: 'Lei 14.133/2021, Art. 75, XV',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 45,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '7e26f823-d29f-465e-9551-8f57e7f82618',
          descricao: 'Lei 14.133/2021, Art. 75, XVI',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 46,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '83047a46-48f1-49a7-876c-49bc69587d84',
          descricao: 'Lei 14.133/2021, Art. 75, VI',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 36,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '505253a6-78ec-4a09-a43a-d4104b1fec11',
          descricao: 'Lei 14.133/2021, Art. 75, IV, a',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 22,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '2c2abf73-f5dd-4f15-b052-4a365af60032',
          descricao: 'Lei 14.133/2021, Art. 75, VII',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 37,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '2b909a36-a466-4630-bd43-a84ee1101bb4',
          descricao: 'Lei 14.133/2021, Art. 75, XIV',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 44,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'cd92ce17-d77e-4dff-8185-3696af9098fa',
          descricao:
            'LEIS 14.133/21, Art.75, II (PNCP e Transfere Gov +Brasil)',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 19,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  ' Informamos que a plataforma MAIS BRASIL que processa informações de transferências de recursos da União passou a receber informações de licitações configuradas a luz da nova Lei 14.133/2021. Dessa forma, essa licitação será informada à plataforma Transfere Gov (+BRASIL) do Governo Federal e no PNCP-Portal Nacional de Contratações Publicas na forma da Lei 14.133/21, Art. 75, II (DISPENSA). O procedimento operacional da Dispensa no BBMNET obedece parcialmente a INSTRUÇÃO NORMATIVA SEGES/ME Nº 67, DE 8 DE JULHO DE 2021 ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '9dda40a3-8913-4d2c-a3b1-48f590249aa0',
          descricao: 'Lei 14.133/2021, Art. 75, IV, b',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 23,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'bb568fec-d922-43c0-9d17-068d3268484a',
          descricao: 'Lei 14.133/2021, Art. 75, V',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 35,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '5fd756e4-7ae6-4f77-bfe5-adfd792040e9',
          descricao: 'Lei 14.133/2021, Art. 75, VIII',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 38,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '6e9862eb-c2d7-4920-94d7-78de66b05c08',
          descricao:
            'LEIS 14.133/21, Art.75, III (PNCP e Transfere Gov + Brasil)))',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 20,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'MUITA ATENÇAO: Informamos que a plataforma MAIS BRASIL que processa informações de transferências de recursos da União passou a receber informações de licitações configuradas a luz da nova Lei 14.133/2021. Dessa forma, essa licitação será informada à plataforma MAIS BRASIL do Governo Federal na forma da Lei 14.133/21 e será publicada no PNCP-Portal Nacional de Contratações Publicas na forma da Lei 14.133/21, Art. 75, III (DISPENSA). No Portal BBMNET os procedimentos operacionais para arealização da dispensa atendem parcialmente aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. \n\n\n',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '35d83dd8-3465-4d51-bc2f-9a5bc71ba42b',
          descricao: 'LEIS 14.133/21, Art.75, I (PNCP e Transfere Gov +Brasil)',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 18,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Informamos que a plataforma MAIS BRASIL que processa informações de transferências de recursos da União passou a receber informações de licitações configuradas a luz da nova Lei 14.133/2021. Dessa forma, essa licitação será informada à plataforma Transfere Gov (+Brasil) do Governo Federal e será publicada no PNCP-Portal Nacional de Contratações Publicas na forma da Lei 14.133/2021, Art. 75, I  (DISPENSA). No Portal BBMNET os procedimentos para a realização da dispensa atendem parcialmente aos requisitos previstos na IN (Instrução Normativa)\nSeges/Ministério da Economia nº 67, de 8 de julho de 2021. \n',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
      ],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 6,
                  name: 'Prorrogação',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
                {
                  id: 5,
                  name: 'Sem Prorrogação',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 6,
                  name: 'Prorrogação',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
                {
                  id: 5,
                  name: 'Sem Prorrogação',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 1,
          name: 'Aquisição de Bens',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 6,
                  name: 'Prorrogação',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
                {
                  id: 5,
                  name: 'Sem Prorrogação',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 6,
                  name: 'Prorrogação',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
                {
                  id: 5,
                  name: 'Sem Prorrogação',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 2,
          name: 'Contratação de Serviços',
        },
      ],
      id: 5,
      name: 'Dispensa',
    },
  ],
  redefinicaoValorItens: [
    {
      id: 1,
      name: 'Sim',
    },
    {
      id: 2,
      name: 'Não',
    },
  ],
  ramoAtividade: [
    {
      uniqueId: '128ce889-4cf9-4576-b8a9-df02fd0770b5',
      descricao: 'Agropecuário',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'cf9b69f0-5f21-4ab1-9c8d-360c42ad3a8e',
          descricao: 'Agricultura – Produção Rural',
          ativo: true,
        },
        {
          uniqueId: '461c05f4-beda-455d-b282-a301d2c540c5',
          descricao: 'Veterinária e vacinas',
          ativo: true,
        },
        {
          uniqueId: 'cd1b4ae7-c81c-4019-b2cf-b7c61d536635',
          descricao: 'Rações para animais',
          ativo: true,
        },
        {
          uniqueId: '57ba0173-58ab-4611-bb5b-260dc75c3e84',
          descricao: 'Animais e Pecuária – De corte e de leite',
          ativo: true,
        },
        {
          uniqueId: '989300ca-f28b-4640-a5f7-998d45224993',
          descricao: 'Mudas, sementes e outros produtos agrícolas',
          ativo: true,
        },
        {
          uniqueId: '9f8079f2-324d-4a4e-9879-e1a0e193ef97',
          descricao:
            'Inseticida, herbicida, fertilizantes e outros Agroquímicos',
          ativo: true,
        },
        {
          uniqueId: '8b8131f4-123b-4c65-91a8-21a6272f44de',
          descricao: 'Exportação de Produtos Agropecuários',
          ativo: true,
        },
        {
          uniqueId: 'ac779225-2117-4e51-8cd0-224a2eebed32',
          descricao: 'Produção de álcool, óleo ou farelo',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'b70cec4b-6692-4cf1-a539-efeb32d545db',
      descricao: 'Alimentação',
      ativo: true,
      segmentos: [
        {
          uniqueId: '6c0109fc-99e8-425c-8d45-09ca0994bf66',
          descricao: 'Gêneros alimentícios',
          ativo: true,
        },
        {
          uniqueId: '2f8b8f73-2128-4263-b3d3-d635be4cee78',
          descricao: 'Cesta básica',
          ativo: true,
        },
        {
          uniqueId: '6212b639-4be6-4358-ace9-b5fa1fe94da2',
          descricao: 'Fornecimento de alimentação, copa, buffet e café',
          ativo: true,
        },
        {
          uniqueId: '97998560-d3c9-4d7f-8ae0-e401ffe992ce',
          descricao: 'Água, bebidas',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'af1d3c70-08fd-4bfd-86df-bc86137a87cd',
      descricao: 'Armazenagem',
      ativo: true,
      segmentos: [
        {
          uniqueId: '7ec26879-68e2-41e2-bdda-671b0a4185e6',
          descricao: 'Silos, depósitos, armazéns',
          ativo: true,
        },
        {
          uniqueId: '74ecb43e-6eac-4303-b69f-fd2bbb831815',
          descricao: 'Tanques, reservatórios, caixa d"água',
          ativo: true,
        },
        {
          uniqueId: '247bac41-492f-4393-a1e8-53e7d96da2be',
          descricao: 'Movimentação de cargas',
          ativo: true,
        },
        {
          uniqueId: '2ec91f99-50cd-4ae5-b6c3-d31ae77d0f2e',
          descricao: 'Container',
          ativo: true,
        },
        {
          uniqueId: 'f17a31e5-bd6e-491e-aa92-83abd77dcd1e',
          descricao: 'Carrinhos em geral, rodízios',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '07101d30-33ed-4135-868e-4d66d9321855',
      descricao: 'Assessorias',
      ativo: true,
      segmentos: [
        {
          uniqueId: '51e98276-e057-4e18-8232-55aab68a0177',
          descricao: 'Consultoria, Assessorias técnicas e de apoio, cobranças',
          ativo: true,
        },
        {
          uniqueId: '18b82eb3-4c6d-475f-bcf8-9409625f97c8',
          descricao: 'Auditoria Independente',
          ativo: true,
        },
        {
          uniqueId: '78c24e9e-1876-463d-bc73-91583a763d86',
          descricao: 'Despachantes, aduaneiros',
          ativo: true,
        },
        {
          uniqueId: '86b24ed6-45a6-4766-8196-2b135109d68c',
          descricao: 'Tradução, revisão ortográfica',
          ativo: true,
        },
        {
          uniqueId: '483062c1-6337-4e19-aac0-abfe9b34b07a',
          descricao: 'Telemarketing',
          ativo: true,
        },
        {
          uniqueId: '25d03f6a-3f8e-40f4-bb94-eddae0108b28',
          descricao: 'Cursos',
          ativo: true,
        },
        {
          uniqueId: '4b05324d-c443-41d4-84f3-1d5c80c13661',
          descricao: 'Concurso Público',
          ativo: true,
        },
        {
          uniqueId: 'cda6ed24-5640-46e9-a7b6-d8086c936f19',
          descricao: 'Pesquisa de opinião pública',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '2d67c703-8e6c-4638-b008-35e2d4904dc5',
      descricao: 'Cartões',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'ff32e20b-61c5-4bc5-99f0-127b89a25283',
          descricao: 'Magnético, pvc, crachá e outros',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'e037e1ab-b415-40c8-964e-8234bb0d9455',
      descricao: 'Combustíveis',
      ativo: true,
      segmentos: [
        {
          uniqueId: '071e1095-90fe-4d95-961c-56a9dda5d38a',
          descricao: 'GLP – botijões',
          ativo: true,
        },
        {
          uniqueId: '589df4d9-26c2-4919-9984-5de55a552050',
          descricao: 'Combustíveis e Lubrificantes',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'ab7d0c26-0285-4295-8be7-50b584b4909b',
      descricao: 'Comunicação/identificação visual',
      ativo: true,
      segmentos: [
        {
          uniqueId: '4a8d95b7-5450-4c74-83d4-14d131a6f60f',
          descricao:
            'Painéis, luminosos, faixas, displays, plaqueta patrimonial',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '9071601c-a818-42d7-a943-a291b1df271b',
      descricao: 'Concessões/exploração/imóvel',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'ad61e9ed-d7c5-43ab-9416-16ee0f3e841c',
          descricao: 'Estacionamento, zona azul',
          ativo: true,
        },
        {
          uniqueId: 'bf225ba3-98d3-429e-8989-019b69ce92f1',
          descricao: 'Pedágios',
          ativo: true,
        },
        {
          uniqueId: '3589e959-9bbd-4852-9cc6-240feaba82a5',
          descricao:
            'Lanchonete, restaurante, bar e locação de máquinas de bebidas',
          ativo: true,
        },
        {
          uniqueId: '3d3fbaec-2979-4b18-8716-b5156e19c091',
          descricao: 'Áreas em aeroportos',
          ativo: true,
        },
        {
          uniqueId: '9d4a0765-a598-4b34-bfc6-68cc1a0bfece',
          descricao: 'Áreas em rodoviárias',
          ativo: true,
        },
        {
          uniqueId: 'e8516c27-e42b-4c77-8d6e-72e465941980',
          descricao: 'Banca de jornal',
          ativo: true,
        },
        {
          uniqueId: 'd0c284a0-0c09-4dd3-9048-779ebf9be790',
          descricao: 'Áreas em sacolões',
          ativo: true,
        },
        {
          uniqueId: '97d55f73-1ac8-4935-859b-ec60ef7b2c31',
          descricao: 'Bancária/instituição financeira e lotérica',
          ativo: true,
        },
        {
          uniqueId: 'c3fac754-a9ca-44df-baf1-d6e3c74638fb',
          descricao: 'Concessões especiais e outros',
          ativo: true,
        },
        {
          uniqueId: '2cc0982a-80f0-4493-a384-9c2b43d91da3',
          descricao: 'Locação e aquisição de Imóveis',
          ativo: true,
        },
        {
          uniqueId: '76b9d89a-6413-4754-ac3e-417da72eceb9',
          descricao:
            'Concessão/Exploração. privatização: Saneamento, elétrica, viária, gestão prisional e outros',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '0d4e0940-e868-43ef-a4e7-f4b60f280ad6',
      descricao: 'Confecções/decoração',
      ativo: true,
      segmentos: [
        {
          uniqueId: '832b7425-f165-44a5-8786-b50a1875c708',
          descricao: 'Uniformes e confecções em geral',
          ativo: true,
        },
        {
          uniqueId: 'd914039c-6db7-4225-98d7-e4a0a679fc2b',
          descricao: 'Aviamentos',
          ativo: true,
        },
        {
          uniqueId: '399caf11-5783-4495-a9e8-0beb08c84f9b',
          descricao: 'Cama, mesa e banho',
          ativo: true,
        },
        {
          uniqueId: 'ad44f513-2e0f-4096-a57f-3a0ac258872b',
          descricao: 'Tecidos',
          ativo: true,
        },
        {
          uniqueId: '6fe2f918-61cd-443c-800d-b7f702040514',
          descricao: 'Calçados',
          ativo: true,
        },
        {
          uniqueId: 'c131d84b-b28e-4e6b-acbc-e0def6c5c474',
          descricao: 'Tapeçaria, persianas, cortinas e decoração',
          ativo: true,
        },
        {
          uniqueId: 'e5f953ec-473d-4ef9-a1b9-bb5e9c0a9565',
          descricao: 'Malas, malotes e encerados',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '3028091e-f5ce-4308-a717-ca2fd281e2e0',
      descricao: 'Didático',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'a407ddaa-6b04-4f5e-9d33-9a7c5cfd4d9c',
          descricao: 'Livros e material didático',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '27b2dfb8-63ee-4ec9-b844-f07e0624a337',
      descricao: 'Eletro eletrônicos/Ótica',
      ativo: true,
      segmentos: [
        {
          uniqueId: '7aedf6d6-67ce-480b-8d49-0ecdbf1e5c9d',
          descricao: 'Cine, som, ótica, TV e projetores',
          ativo: true,
        },
        {
          uniqueId: '69562010-de91-44ba-bd9f-538212e6e330',
          descricao: 'Eletrodomésticos, ventilador, bebedouro',
          ativo: true,
        },
        {
          uniqueId: '40d09338-35b1-4c81-8057-4e16035376b4',
          descricao: 'Exaustor e exaustão',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'e8a0d067-05b4-4193-9389-df3ee86f2fe4',
      descricao: 'Embalagens e lacres',
      ativo: true,
      segmentos: [
        {
          uniqueId: '8ebbc712-0203-4d8f-acd6-a60e4ab9df6d',
          descricao: 'Embalagens e lacres em geral',
          ativo: true,
        },
        {
          uniqueId: '5a2fa5aa-ee57-49ba-90ce-5309fe531940',
          descricao: 'Utensílios de copa e cozinha',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '4fb1d102-606b-46a0-865d-d5d51a1729f0',
      descricao: 'Engenharia - materiais',
      ativo: true,
      segmentos: [
        {
          uniqueId: '211c1d5d-7940-4957-8810-13a9e2344901',
          descricao: 'Construção, madeiras',
          ativo: true,
        },
        {
          uniqueId: '2526e2bf-02ee-466c-a36d-9085bdf7d503',
          descricao: 'Hidráulicos, saneamento',
          ativo: true,
        },
        {
          uniqueId: '460705d0-bf69-434c-a860-9e3ff3ab5df3',
          descricao: 'Asfálticos, concreto',
          ativo: true,
        },
        {
          uniqueId: '5e2514b9-f376-44fa-93e0-c4a51565f49d',
          descricao: 'Elétricos',
          ativo: true,
        },
        {
          uniqueId: '1c5fe445-d98f-439a-8bb2-026f2ecfcf42',
          descricao: 'Material de Sinalização',
          ativo: true,
        },
        {
          uniqueId: '9aa1ec33-3848-4a3e-96b0-300302e0e4a9',
          descricao: 'Materiais de Pintura',
          ativo: true,
        },
        {
          uniqueId: '7c338323-e0f7-4e84-8253-b386f516612a',
          descricao: 'Banco de concreto para praças',
          ativo: true,
        },
        {
          uniqueId: 'a2b15b4d-34fb-4453-b577-2517f2d261bc',
          descricao: 'Materiais Minerais (Pedras)',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '39dea938-c13c-49ba-a323-5d93fa371b2d',
      descricao: 'Engenharia - serviços',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'c799c7ba-e11a-4726-a111-1d3632606aca',
          descricao: 'Reformas, edificações e demolição',
          ativo: true,
        },
        {
          uniqueId: 'd49541c1-e1f4-433a-a694-9969cf7f8996',
          descricao:
            'Saneamento, esgoto, dutos, rede de distribuição de água, rede de gás',
          ativo: true,
        },
        {
          uniqueId: '34440ea7-7293-442e-9ee2-e63c3726961a',
          descricao: 'Pavimentação, drenagem',
          ativo: true,
        },
        {
          uniqueId: '93db1c53-7a02-44bd-94fd-e36e6487a50a',
          descricao: 'Muro de arrimo, contenções',
          ativo: true,
        },
        {
          uniqueId: '664b8629-9897-4020-bed8-28671a63abd7',
          descricao: 'Pontes, obras de arte, viadutos, túneis, passarelas',
          ativo: true,
        },
        {
          uniqueId: 'e82e1cdd-d855-40bd-9c05-38db8ea606af',
          descricao: 'Serviços de terraplanagem',
          ativo: true,
        },
        {
          uniqueId: '9466279a-9bea-4e28-9b86-dea5fa391b1c',
          descricao:
            'Poços tubulares, artesianos, perfuratriz, mineração, escavação',
          ativo: true,
        },
        {
          uniqueId: '690dc2a0-7e7b-475c-b105-77a61d275e32',
          descricao: 'Elétrica, redes, isolação, serviços',
          ativo: true,
        },
        {
          uniqueId: '91305356-51f0-47a3-a3a4-8e23ef6193a9',
          descricao: 'Portos, aeroportos',
          ativo: true,
        },
        {
          uniqueId: '32942c74-2335-4339-a42e-72691a9bfab2',
          descricao: 'Supervisão de obras',
          ativo: true,
        },
        {
          uniqueId: 'aca4a5f6-6ed9-4cc7-a7b6-8add84fe4360',
          descricao: 'Projetos de engenharia e arquitetura, maquetes',
          ativo: true,
        },
        {
          uniqueId: 'e718c034-b984-4346-9608-36cbeb2c2644',
          descricao: 'Sinalização viária',
          ativo: true,
        },
        {
          uniqueId: '020ddc6b-ca3c-47b6-ba0a-4539ff295e69',
          descricao: 'Sinalização semafórica, controle do trânsito, radares',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '95e0c438-6df7-4602-83bb-a26a44962168',
      descricao: 'Equipamentos/ferramentas',
      ativo: true,
      segmentos: [
        {
          uniqueId: '8839dd72-4d10-4b4f-a7b0-a724d900d11a',
          descricao: 'Balanças',
          ativo: true,
        },
        {
          uniqueId: 'e523f5de-72f7-4007-b3bd-f5e5b40f196c',
          descricao: 'Ferramentas e bancadas',
          ativo: true,
        },
        {
          uniqueId: '08714be4-bbb9-48ac-a5f0-7e3380789689',
          descricao: 'Agrícolas, implementos',
          ativo: true,
        },
        {
          uniqueId: '63185304-387f-44f3-9cd9-4d6c235523d7',
          descricao: 'Máquinas pesadas e equipamentos de construção',
          ativo: true,
        },
        {
          uniqueId: 'e561ad02-e481-4dfe-b31f-7929f50fc0ad',
          descricao: 'Medição em geral',
          ativo: true,
        },
        {
          uniqueId: '586f3352-9295-4a3e-a518-c8a620767bb6',
          descricao: 'Hidráulicos, compressores e motores, bombas e outros',
          ativo: true,
        },
        {
          uniqueId: 'b079ceef-e6e7-4e36-b97b-e62b209eb2ff',
          descricao: 'Hidrômetros',
          ativo: true,
        },
        {
          uniqueId: 'c7433c70-c45d-407c-a935-ef0c6d3aa582',
          descricao: 'Energia: geradores, transformadores, painéis e outros',
          ativo: true,
        },
        {
          uniqueId: '81f2b33f-f6e9-4e79-abf3-bee1bad8e68c',
          descricao: 'Empilhadeiras, pallets',
          ativo: true,
        },
        {
          uniqueId: 'e9c8ea5e-1389-4fdd-96c1-2a1ac668bcdf',
          descricao: 'Guincho elétrico',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '16e37cfa-0613-443e-b922-4800aaffa105',
      descricao: 'Escritório e gráfica',
      ativo: true,
      segmentos: [
        {
          uniqueId: '6086846c-1416-41d2-a353-4c7e91dfb983',
          descricao: 'Materiais de escritório, expediente, papéis, formulários',
          ativo: true,
        },
        {
          uniqueId: '86a1d7c9-10bc-4f9c-92e0-bcf43955ebbe',
          descricao:
            'Equipamentos para escritórios, máquinas ( fac-símile, mimeógrafo, franqueadora, guilhotina, fragmentadora, contadores, escrever e calcular e outros)',
          ativo: true,
        },
        {
          uniqueId: '1d9d8a5c-2929-443c-92ec-11a0035c6b32',
          descricao:
            'Copiadora, reprografia, encadernação, leitora, , duplicador, plastificação',
          ativo: true,
        },
        {
          uniqueId: 'e84fb99e-8bf2-4192-ac48-0259be5c83d8',
          descricao: 'Serviços gráficos, impressos e edições',
          ativo: true,
        },
        {
          uniqueId: 'd04fc56d-dd80-45d0-8a45-8e264c70e174',
          descricao:
            'Máquinas e materiais gráficos, serrilhadora, blocadora, envelopadora, contadora de cédulas e outros',
          ativo: true,
        },
        {
          uniqueId: '0e3f9f78-46f8-4a73-a0bd-5e6d7dfffb25',
          descricao:
            'Microfilmagem, arquivamento, gerenciamento eletrônico de documentos (GED), digitalização, biblioteconomia',
          ativo: true,
        },
        {
          uniqueId: '4a8ffdec-337c-40ff-a9a6-b5fb0c6e7658',
          descricao: 'Carimbos, chaves',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '26a068b8-1b0a-4188-8ba3-947c46495f06',
      descricao: 'Especializados',
      ativo: true,
      segmentos: [
        {
          uniqueId: '3c1c7cef-7338-4891-9117-6e8da76226b3',
          descricao:
            'Satélite, sinais, sistema de posicionamento global, rastreamento, radar aéreo controlador de vôo, sistema de navegação aérea, controle de espaço aéreo',
          ativo: true,
        },
        {
          uniqueId: '05e29d39-125e-4132-9550-cdcc5c1e09a5',
          descricao:
            'Serviços técnicos, topográficos, sondagens, fundações, levantamentos estudos, saneamento ambiental, georreferenciados, inspeções',
          ativo: true,
        },
        {
          uniqueId: '54456152-394f-49c4-835a-ec899e34287c',
          descricao: 'Isolação térmica e acústica',
          ativo: true,
        },
        {
          uniqueId: 'a122b43a-cf4e-45fc-a2e7-e339a909d8c6',
          descricao: 'Caldeiraria, gerador de vapor, trocador de calor',
          ativo: true,
        },
        {
          uniqueId: 'f0ea0870-b4d0-4958-989d-6d61efe7e5bc',
          descricao:
            'Usinagem, tornearia, peças sob desenho, mecânica industrial',
          ativo: true,
        },
        {
          uniqueId: 'c7cde54d-2564-4499-bf07-cedea5793e15',
          descricao: 'Meteorologia',
          ativo: true,
        },
        {
          uniqueId: '45ae8700-5ac1-4b6b-ae58-8cf62ecbe959',
          descricao: 'Cabines Sanitárias, banheiros químicos',
          ativo: true,
        },
        {
          uniqueId: 'be84cede-06e2-44ee-986a-9e5cc743d7e3',
          descricao:
            'Câmaras frigoríficas, cozinha industrial, lavanderia, limpeza e outros',
          ativo: true,
        },
        {
          uniqueId: '27782455-99ec-4d61-bedb-503f428e4680',
          descricao: 'Irrigação, hidrologia',
          ativo: true,
        },
        {
          uniqueId: 'eb3afda5-1d0c-4512-8dca-c78a0a717d12',
          descricao: 'Tratamento de água, análises de água',
          ativo: true,
        },
        {
          uniqueId: '59a873e3-3db4-47aa-9d86-80c55b3fc335',
          descricao: 'Blindagem',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '13fa0334-432b-4d37-ac4c-800a1bd41759',
      descricao: 'Esportivos/musicais',
      ativo: true,
      segmentos: [
        {
          uniqueId: '121c2676-a468-4801-a097-be5f81b54dc3',
          descricao:
            'Equipamentos e materiais esportivos, instrumentos musicais, artes',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '22de41ee-a129-4184-9f74-30614d6e216b',
      descricao: 'Eventos',
      ativo: true,
      segmentos: [
        {
          uniqueId: '89a16a2f-94b1-4a76-911d-b6e577386e5d',
          descricao: 'Organização de eventos',
          ativo: true,
        },
        {
          uniqueId: '91c8499b-accc-416c-921b-2d42bb571397',
          descricao: 'Cobertura e material fotográfico, filmagem',
          ativo: true,
        },
        {
          uniqueId: '896a11a7-2dd4-4af6-8406-d9eb4ddcf135',
          descricao: 'Sonorização',
          ativo: true,
        },
        {
          uniqueId: '6cd7c06c-d4f4-4e66-94bc-3e006faa7a96',
          descricao: 'Iluminação',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '7c317e65-62ae-464b-a609-589aadeb7b97',
      descricao: 'Ferroviários',
      ativo: true,
      segmentos: [
        {
          uniqueId: '5a675f96-bb7b-43b9-ba4b-ea9d0c873dae',
          descricao: 'Serviços, peças e equipamentos',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'e33da83a-19d9-419d-a743-562a8e42af14',
      descricao: 'Funerária',
      ativo: true,
      segmentos: [
        {
          uniqueId: '30b929df-eb49-4318-b7f5-e1649d4f8503',
          descricao: 'Urnas, funerários, artigos religiosos',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '4373d7d4-a5fc-455f-a91f-8bd6e06341cb',
      descricao: 'Hospedagem',
      ativo: true,
      segmentos: [
        {
          uniqueId: '996eff3a-a1cb-42ad-8a58-ceeac60548a1',
          descricao: 'Hotelaria',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '3c35f491-95ce-4af1-a4df-cb65d1d6f670',
      descricao: 'Infantil/Playground',
      ativo: true,
      segmentos: [
        {
          uniqueId: '0e7fffbe-37c9-4a14-ae43-89150fd02838',
          descricao: 'Brinquedos, material infantil, playground',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '8f744d3a-450c-46e3-9c28-ce3caab6b6e4',
      descricao: 'Informática',
      ativo: true,
      segmentos: [
        {
          uniqueId: '3d0627aa-5a18-43ba-bae3-46f231cdebb0',
          descricao: 'Softwares, sistemas, serviços de rede, serviços técnicos',
          ativo: true,
        },
        {
          uniqueId: '3050792b-a34d-4800-828e-ea54fa9157ef',
          descricao: 'Equipamentos e periféricos, compra, instalação e locação',
          ativo: true,
        },
        {
          uniqueId: '20f54f8a-d7c9-4a08-afba-d36493b1ce05',
          descricao: 'Manutenção de equipamentos de informática',
          ativo: true,
        },
        {
          uniqueId: 'f9b3ab70-4a4d-4ddb-9420-f6dc548797a3',
          descricao: 'Suprimentos, formulários',
          ativo: true,
        },
        {
          uniqueId: '7d7e14c3-7e1d-4dd8-88be-d52c4ea6bc22',
          descricao: 'Sistema URA, correio de voz e outros',
          ativo: true,
        },
        {
          uniqueId: '2f725a8e-4944-4c5c-85d5-0b9dd7182fd2',
          descricao: 'Relógio de ponto e controle de acesso',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '923b0505-1404-4b78-bffc-388d128a2e2a',
      descricao: 'Instalações',
      ativo: true,
      segmentos: [
        {
          uniqueId: '8bae4eeb-d908-4b68-80cc-95f1b022e5bc',
          descricao:
            'Manutenção predial, hidráulica, hidro-sanitárias, elétrica, pisos, pintura, telhados',
          ativo: true,
        },
        {
          uniqueId: '4c22ae2c-8ea2-431f-a101-4046bfea0ddd',
          descricao: 'Impermeabilização, fachadas',
          ativo: true,
        },
        {
          uniqueId: '7d443d1a-c712-4c7b-9310-4698ca5bac54',
          descricao:
            'Estrutura metálica, andaimes, palco, arquibancadas, abrigos, toldos',
          ativo: true,
        },
        {
          uniqueId: '5755245b-76cc-4baf-94fc-162982bedeea',
          descricao: 'Ar condicionado, limpeza de dutos',
          ativo: true,
        },
        {
          uniqueId: '62deaede-8473-4289-9a75-a2707703601f',
          descricao:
            'Elevadores, monta carga, escadas rolantes, esteiras transportadoras',
          ativo: true,
        },
        {
          uniqueId: '6f5195a7-766f-4553-b751-4f8045de57c7',
          descricao: 'Vidraçaria, marcenaria, serralheria',
          ativo: true,
        },
        {
          uniqueId: '6f280a17-9808-4473-83e3-1edf6d7ff2f2',
          descricao: 'Divisórias',
          ativo: true,
        },
        {
          uniqueId: 'c1730173-8659-4502-a433-656cac0ceaf4',
          descricao:
            'Segurança: alarme, portas especiais, cofres, circuito de TV (CFTV), catracas, detector de metais, raio-x de bagagem',
          ativo: true,
        },
        {
          uniqueId: '7ddd7242-5760-44e9-92f9-c682463d250c',
          descricao:
            'Telecomunicações, sistemas, cabos, telefones, rádio-chamada, transceptores',
          ativo: true,
        },
        {
          uniqueId: '225c6f86-dc6e-4c95-b2a3-da7f30652a63',
          descricao: 'Equipamentos e material contra-incêndio e serviços',
          ativo: true,
        },
        {
          uniqueId: '20cb4ea1-6f0f-4495-9360-5ce64e0781d8',
          descricao: 'Pára-raios',
          ativo: true,
        },
        {
          uniqueId: '2f59c66e-994e-4d4c-89ca-4cce7b194af4',
          descricao: 'Manutenção em campo de futebol e estádios',
          ativo: true,
        },
        {
          uniqueId: 'dfd1db07-9022-49bc-b0ad-cb62f0b9ffd7',
          descricao: 'Piso Laminado',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'cdf2382c-3849-4d67-8c88-71393e20e878',
      descricao: 'Leilões',
      ativo: true,
      segmentos: [
        {
          uniqueId: '2fc6749e-623a-4c7b-9282-f4b02188e219',
          descricao:
            'Transportes - veículos, aeronaves, embarcações, carros, utilitários, caminhões, motos, barcos, navios, helicópteros, aviões e suas respectivas peças',
          ativo: true,
        },
        {
          uniqueId: '2dbc3871-8e22-43cf-8e81-9fa5b93eb33f',
          descricao:
            'Imóveis - casas, terrenos, lotes, glebas, apartamentos, prédios, salas comerciais',
          ativo: true,
        },
        {
          uniqueId: '2eea6142-6c62-4e64-b083-a5f7d8dfde65',
          descricao:
            'Eletrodomésticos, eletro-eletrônicos, reprográficos, equip. informática, ar-condicionado, equip. segurança eletrônica, painéis eletrônicos, extintores, pára-raios, etc',
          ativo: true,
        },
        {
          uniqueId: 'e8455f97-b4d4-4254-9065-5957473bdd1f',
          descricao:
            'Materiais de construção, saneamento, hidráulicos, elétricos, sinalização',
          ativo: true,
        },
        {
          uniqueId: 'fe3e63b7-356b-41f1-85fb-1b8e68d3b594',
          descricao:
            'Máquinas, equipamentos e ferramentas: industriais, de engenharia (pesados), irrigação',
          ativo: true,
        },
        {
          uniqueId: 'b2479f5c-080f-46da-8f50-8beb9f2a15f9',
          descricao:
            'Livros, jóias, publicações, perfumes, obras de arte, títulos e ações. Ver observação',
          ativo: true,
        },
        {
          uniqueId: '7fb84ba3-2fc0-4e05-8581-1e3fb4822050',
          descricao: 'Petrobrás e Ferroviário',
          ativo: true,
        },
        {
          uniqueId: '6f836182-3d0a-49f8-8a08-44053e3bf9b9',
          descricao: 'Inservíveis e Sucatas',
          ativo: true,
        },
        {
          uniqueId: '269647d1-e293-4a14-9cb7-69e675592388',
          descricao:
            'Animais, materiais e equipamentos agropecuários, veterinários, inseticidas',
          ativo: true,
        },
        {
          uniqueId: '3d2fcbb3-91f8-465f-837b-aa53ec2dc10b',
          descricao:
            'Produtos químicos e seus derivados, gases, resíduos, combustíveis e seus derivados',
          ativo: true,
        },
        {
          uniqueId: 'a7bb7946-3ad6-49e8-9e20-fec6e93af583',
          descricao: 'Alimentícios e bebidas',
          ativo: true,
        },
        {
          uniqueId: 'd1f06dbd-c3db-43b9-96ec-7fbf13beecd9',
          descricao:
            'Equipamentos, materiais e instrumentais: médicos, laboratoriais, medicamentos e balanças',
          ativo: true,
        },
        {
          uniqueId: '44493389-08e5-4fca-8f0f-f260564dbb34',
          descricao:
            'Materiais de escritório, didáticos, informática, utensílios domésticos, embalagens, limpeza, cosméticos',
          ativo: true,
        },
        {
          uniqueId: 'f35f6365-73ec-4c31-a8ce-9a65699d4e75',
          descricao: 'Móveis em geral',
          ativo: true,
        },
        {
          uniqueId: 'df1c2260-df1e-43ec-8e98-3c6e7e2d7efb',
          descricao:
            'Confecções, malas, calçados, tecidos, esportivos, brinquedos, segurança',
          ativo: true,
        },
        {
          uniqueId: '2e20fcfa-bde9-45cf-81dc-287797dc0588',
          descricao: 'Funerários',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '4c867b6e-a3eb-4cfe-8575-54c5edc8d12d',
      descricao: 'Mobiliários',
      ativo: true,
      segmentos: [
        {
          uniqueId: '01bee8a7-a0ff-4cc8-ade3-172b14615630',
          descricao: 'Escritório',
          ativo: true,
        },
        {
          uniqueId: '66827952-cf9a-40b0-a5f0-c891cc31731b',
          descricao: 'Arquivo deslizante',
          ativo: true,
        },
        {
          uniqueId: 'd7f53b5b-f6a4-49a0-8149-909cf3e8bbae',
          descricao: 'Médico, laboratorial, cadeira de rodas',
          ativo: true,
        },
        {
          uniqueId: '553e2cc4-9fbd-46e5-9ea3-ee69f89e9b34',
          descricao: 'Escolar',
          ativo: true,
        },
        {
          uniqueId: '9ed8f09e-5faf-4971-a5d5-a45d0e20c55f',
          descricao: 'Quarto, sala, cozinha, clubes/lazer',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '91d13e36-e61c-450a-8996-a35479eb5b3e',
      descricao: 'Passagens',
      ativo: true,
      segmentos: [
        {
          uniqueId: '4f1915d2-322c-4215-b390-9c43d7170d4a',
          descricao: 'Agenciamento de passagens aéreas, marítimas e terrestres',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '7c03e654-02e1-4e1c-b298-9c9636928e78',
      descricao: 'Petrobrás',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'edd76586-d87f-45bd-b299-9afa67923bab',
          descricao: 'Serviços, peças e equipamentos',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'f88b664f-31f9-468e-8331-8ca6bf6acb34',
      descricao: 'Prestação de serviços – mão de obra',
      ativo: true,
      segmentos: [
        {
          uniqueId: '6f089eab-2ff6-48b1-b2b8-75a80c5fb3b3',
          descricao: 'Limpeza, conservação, predial, hospitalar e higienização',
          ativo: true,
        },
        {
          uniqueId: '343f82d9-b10d-40a0-81a8-6eb78380cfbb',
          descricao: 'Paisagismo, jardinagem, plantio, cortes e podas',
          ativo: true,
        },
        {
          uniqueId: '6424cb5d-a13d-4762-9c9b-263f22a29336',
          descricao: 'Coleta de lixo e limpeza urbana',
          ativo: true,
        },
        {
          uniqueId: 'f690e8df-c80b-436f-affe-20ad41365184',
          descricao: 'Incineração, tratamento de resíduos sólidos',
          ativo: true,
        },
        {
          uniqueId: 'e0d6f8e4-fb56-4384-a924-745acadf777a',
          descricao: 'Lavagem de roupa',
          ativo: true,
        },
        {
          uniqueId: 'e9ae37f6-a81b-4887-9721-7648d48e22b8',
          descricao: 'Limpeza de galerias, dutos, dragagem, desassoreamento',
          ativo: true,
        },
        {
          uniqueId: '164d75c6-49f8-47b6-b011-3f8bb5377b54',
          descricao: 'Cercamento e alambrado',
          ativo: true,
        },
        {
          uniqueId: '8313e8b7-e108-4fa7-b406-3098f8524a15',
          descricao:
            'Leitura de hidrômetros, de energia elétrica e gás, coletor de dados',
          ativo: true,
        },
        {
          uniqueId: 'e9b44fb2-11be-465e-9015-33f7c91b6148',
          descricao:
            'Recursos humanos: ascensorista, copa, porteiro, telefonista e outros',
          ativo: true,
        },
        {
          uniqueId: '5b9997a1-2cc7-45b0-8707-2af4bef04da3',
          descricao: 'Serviços de vigilância, armada e desarmada',
          ativo: true,
        },
        {
          uniqueId: '24ad0619-839d-4e0b-b4a3-03bbdad24071',
          descricao: 'Serviços de digitação',
          ativo: true,
        },
        {
          uniqueId: 'b47cab3d-a68f-480c-b59b-6af3c16b7b5d',
          descricao: 'Administração de creches e hospitalar',
          ativo: true,
        },
        {
          uniqueId: 'c69b9723-cdfa-4db1-8738-de4d1e5f4f0f',
          descricao:
            'Manuseio, tratamento de documentos, distribuição e entrega',
          ativo: true,
        },
        {
          uniqueId: 'd3d90536-5a36-42f6-92d0-e42a54560253',
          descricao: 'Estágio, estagiários',
          ativo: true,
        },
        {
          uniqueId: '66072c7f-de22-4fec-a9c5-40bbb62d98b4',
          descricao: 'Manutenção de Veículos',
          ativo: true,
        },
        {
          uniqueId: 'd7cfb5ea-cb4f-4ba7-838a-65d8029ed640',
          descricao:
            'Locação de máquinas médias, máquinas pesadas e caminhões com combustível, operadores e/ou motoristas',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'bf741153-3124-43cb-9228-f42bd41ba9af',
      descricao: 'Produtos de limpeza',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'b41e11e7-aec6-4037-884a-4a208e538747',
          descricao: 'Produtos de higiene, limpeza e higiene pessoal',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'b6ce3788-9583-4c92-9fa1-cb2a340ef2ad',
      descricao: 'Publicidade',
      ativo: true,
      segmentos: [
        {
          uniqueId: '94dee4fa-2ce1-4140-8c56-dbed900053e2',
          descricao: 'Propaganda, assessoria de imprensa',
          ativo: true,
        },
        {
          uniqueId: '01d1cc65-6972-43c7-a092-59c8fc13cb83',
          descricao: 'Brindes, medalhas, troféus, brasões, distintivos',
          ativo: true,
        },
        {
          uniqueId: 'dd5d2c64-acfd-48ee-918c-1d343e436ed4',
          descricao:
            'Publicações, divulgação, clipping, radiodifusão, assinaturas',
          ativo: true,
        },
        {
          uniqueId: '68461591-87c1-4254-a8ad-325bbe3d9ffa',
          descricao: 'Exploração de espaço publicitário',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '0b7634a1-e7f9-4cb2-83ec-23f6d0c299c5',
      descricao: 'Químicos',
      ativo: true,
      segmentos: [
        {
          uniqueId: '9a3816be-8195-4781-96c0-b2dc3f034c01',
          descricao:
            'Químicos e produtos para tratamento de água e produtos especiais',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'b0981f8b-7b85-4c90-b035-0409247192fa',
      descricao: 'Saúde',
      ativo: true,
      segmentos: [
        {
          uniqueId: '8de78f05-2984-4ee2-be8c-a9a8e9aa12a5',
          descricao: 'Medicamentos',
          ativo: true,
        },
        {
          uniqueId: 'bdb94322-8a3a-4e03-8823-38cb74c9a9af',
          descricao:
            'Materiais, produtos e utensílios médico, hospitalar e laboratorial',
          ativo: true,
        },
        {
          uniqueId: '0706d51d-8b1e-4d92-af4d-bea8596ab043',
          descricao:
            'Equipamentos e instrumentos, hospitalares, laboratoriais e científicos',
          ativo: true,
        },
        {
          uniqueId: 'e20ba9b4-0f6a-42c0-9d2c-668d41febb0d',
          descricao:
            'Serviços: engenharia clínica, exame médico ocupacional, admissional, serviços médicos e análises laboratoriais',
          ativo: true,
        },
        {
          uniqueId: 'ceefb1af-901b-4cde-8fc7-ed4968407462',
          descricao: 'Gases',
          ativo: true,
        },
        {
          uniqueId: 'f7e2c816-f6ef-4d75-b18e-817e705dce0c',
          descricao: 'Filmes, raio -x, radiológicos',
          ativo: true,
        },
        {
          uniqueId: 'e1b3143e-7d78-4e3a-8fd0-9b0eb9cc2b55',
          descricao: 'Dieta enteral e parenteral',
          ativo: true,
        },
        {
          uniqueId: 'fb1a8b9a-708f-4032-b654-dd403fb4afa3',
          descricao: 'Odontológicos',
          ativo: true,
        },
        {
          uniqueId: '5607191f-5d49-4fbb-8eae-424b60f75c7f',
          descricao: 'Leite e Suplementos',
          ativo: true,
        },
        {
          uniqueId: '679ab94d-1bc2-4907-acf8-b1ae2e59e163',
          descricao: 'Oftalmológico',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'e9b1b868-c0f6-4f9e-9bde-9cdfd0e963ad',
      descricao: 'Segurança/Proteção',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'fff8bb43-6c0f-48af-848e-72a2b8990cce',
          descricao: 'Equipamentos e produtos de proteção individual',
          ativo: true,
        },
        {
          uniqueId: '76002baf-3a20-4433-8f27-3fafe419b9da',
          descricao: 'Armas, munições, bélicos e explosivos',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '709ae1c1-e341-477c-a85d-10d7cad913f9',
      descricao: 'Seguros',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'f5b7bce7-7b5a-43df-bd33-a01fa12c88db',
          descricao: 'Seguros',
          ativo: true,
        },
        {
          uniqueId: '7e188692-6e46-49ec-8f72-7183eb262750',
          descricao: 'Assistência Médica',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '9b97702a-9d76-4170-aba1-5c77ac9e6de7',
      descricao: 'Sistema de vales',
      ativo: true,
      segmentos: [
        {
          uniqueId: '14993c9c-ba5d-4f93-923f-ff3c20732fde',
          descricao: 'Refeição, combustível, transporte',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '21628250-1333-442c-a1a5-37d998d3c839',
      descricao: 'Transportes aéreos',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'a42e7762-f3a9-481e-a8bf-e62bc0edba64',
          descricao:
            'Aquisição, locação, manutenção e peças para aviões, helicópteros e equipamentos aéreos, transporte internacional',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '5d410bf3-8630-43e3-afe5-850326e8fb33',
      descricao: 'Transportes náuticos',
      ativo: true,
      segmentos: [
        {
          uniqueId: '7a011a11-6668-4395-82db-47e6a27fc815',
          descricao:
            'Aquisição, locação, manutenção e peças de equipamentos náuticos, transporte marítimo/fluvial',
          ativo: true,
        },
        {
          uniqueId: 'c8fa540f-b00f-4e7c-bec2-8611c79a3d64',
          descricao: 'Docagem',
          ativo: true,
        },
        {
          uniqueId: 'f6329b5f-b765-49c2-a865-038ea5e88867',
          descricao: 'Balsas',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '4469a1f9-64dd-4363-97c1-1d89c7e08abe',
      descricao: 'Transportes rodoviários',
      ativo: true,
      segmentos: [
        {
          uniqueId: '936f7100-8137-4a9c-bf23-b025f6ee522f',
          descricao:
            'Aquisição de veículos, caminhões, ônibus, ambulâncias, motos',
          ativo: true,
        },
        {
          uniqueId: '9a1d8f22-8b83-4518-912e-ecb038907932',
          descricao: 'Serviços de locação de veículos, motos',
          ativo: true,
        },
        {
          uniqueId: 'af9b5430-30c2-49ee-bba3-87e151e767c9',
          descricao:
            'Serviços de moto-boy e transporte: passageiros, documentos',
          ativo: true,
        },
        {
          uniqueId: '34b2ac27-26ef-499e-b500-aba5197bb3e6',
          descricao: 'Serviços de transportes de valores',
          ativo: true,
        },
        {
          uniqueId: 'fd42815a-7bca-42a3-9ba6-1e0b1b46f121',
          descricao:
            'Manutenção, peças e pneus, veículos, caminhões, ônibus, ambulâncias, motos',
          ativo: true,
        },
        {
          uniqueId: '4b7bf41c-470b-45d1-87f0-23ddd73a0200',
          descricao: 'Carroceria, guinchos, guindastes',
          ativo: true,
        },
        {
          uniqueId: '81a71919-4a8f-4ee6-8b32-bd1090938fbd',
          descricao: 'Aquisição/serviços de caminhão pipa',
          ativo: true,
        },
        {
          uniqueId: 'f32d4eaf-ce12-47ce-b10c-48f432b0be6d',
          descricao: 'Transporte de cargas: terrestre/aéreo/marítimo',
          ativo: true,
        },
        {
          uniqueId: 'f7370cd0-f309-4ffc-8b3a-27e034874caa',
          descricao: 'Serviços de Transporte Escolar',
          observacao: '',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'f4ac2ba4-f1bd-4a45-bb30-7c2744893584',
      descricao: 'Prestação de Serviços - Outros',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'edfb127d-4e74-4062-bb37-d25c13ab9065',
          descricao:
            'Contratação de empresa especializada em prestação serviço de atendimento multiprofissional terapêutico educacional',
          ativo: true,
        },
        {
          uniqueId: 'c845b2d3-ad5d-4a4f-99f8-6267bd71c547',
          descricao: 'Arbitragem',
          ativo: true,
        },
        {
          uniqueId: '65e406a8-21b8-4bf0-a6f5-d6c04fa7d2f0',
          descricao: 'Serviço de Brigadistas',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'f6a5c03b-69fd-493c-928f-8ee3d9b35c94',
      descricao: 'Prestação de Serviços Especializados',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'f9bd3837-4dba-409f-b372-074d55699b2e',
          descricao:
            'Empresa Especializada em Seleção/Contratação (Cargos, Eleições)',
          ativo: true,
        },
        {
          uniqueId: '3ffbbd74-36f1-4533-a73b-4e44d5032c08',
          descricao: 'Manutenção de Sistema de Combate a Incêndio',
          ativo: true,
        },
        {
          uniqueId: '5a5c212d-4353-419a-b7a2-5644eeaac323',
          descricao:
            'Análise Laboratorial em Amostra de Efluente de Estação de Tratamento de Esgoto',
          ativo: true,
        },
        {
          uniqueId: '71babd2a-f368-4c7b-a286-32748c4e5730',
          descricao: 'Manutenção e/ou recarga',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '471d4d06-b158-4996-a1e6-5d6ba8c4babf',
      descricao: 'Agenciamento de Viagens',
      ativo: true,
      segmentos: [
        {
          uniqueId: '71f1bac1-dc04-457d-a228-903b5c0719a6',
          descricao: 'Passagens / Coordenação / Tradutor / Guia',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: '29006f13-886d-43c5-854f-d5e2b561feb6',
      descricao: 'Artigos de papelaria',
      ativo: true,
      segmentos: [
        {
          uniqueId: 'cc38f0b5-9847-457a-a63c-1c6d43199635',
          descricao: 'Papelarias em geral',
          ativo: true,
        },
      ],
    },
    {
      uniqueId: 'da3c1cf0-8efd-4ba1-a521-ffa4ce6b9510',
      descricao: 'Instituições Financeiras',
      observacao: '',
      ativo: true,
      segmentos: [
        {
          uniqueId: '633a62d4-51a1-4f89-b16f-87665f5d2988',
          descricao: 'Prestação de Serviços Bancários',
          observacao: '',
          ativo: true,
        },
      ],
    },
  ],
};
