import styled from 'styled-components';

export const DetailsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.light};
  max-width: 1440px;
  padding: 24px;
  font-family: Montserrat, sans-serif;

  #card-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 4px;
    font-size: 1.7rem;
    font-weight: bold;
    max-width: 16em;
    min-width: 16em;
    padding-right: 1em;
    padding-left: 1em;
    max-height: 3em;
  }

  .lote-dados {
     display: flex;
     flex-direction: column;
     font-weight: bold; 
  }

  .lote {
    width: 100%;
    background-color: #f4f4f4;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 5px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    min-height: 50px;
    margin-bottom: 30px;
    align-items: center;
    align-content: center;
    position: relative; /* Ensures the arrow can be positioned within the div */
  }

  .title-lote {
    width: 100%;
    color: #084ca4;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    margin-left: 2em;
  }

  /* Caret-like Arrow styles */
  .arrow {
    width: 12px;
    height: 12px;
    border-left: 2px solid darkgray;
    border-bottom: 2px solid darkgray;
    transform: rotate(135deg); /* Points the arrow downwards */
    position: absolute;
    right: 10px;
    transition: transform 0.075s ease-in-out;
    margin-right: 1em;
  }

  /* Rotate arrow upwards when visible */
  .arrow.up {
    transform: rotate(-45deg); /* Rotate to point upwards */
  }

  .button-edital {
    background-color: ${({ theme }) => theme.primary};
    color: white;
    font-weight: bold;
    font-family: Montserrat;
    font-size: 1.7rem;
    min-height: 3em;
    margin-left: 0.5em;
    max-height: 3em !important;
  }

  .button-lotes {
    
    color: white;
    font-weight: bold;
    font-family: Montserrat;
    font-size: 1.7rem;
    min-height: 3em;
    margin-left: 0.5em;
    max-height: 3em !important;
  }

  #card-lote {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 1em;
    margin-right: 1em;
    border-radius: 4px;
    font-size: 1.7rem;
    font-weight: bold;
    max-width: 16em;
    min-width: 16em;
    padding-right: 1em;
    padding-left: 1em;
    max-height: 3em;
  }
  #card-download {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 4px;
    font-size: 1.7rem;
    font-weight: bold;
    max-width: 16em;
    min-width: 16em;
    padding-right: 1em;
    padding-left: 1em;
    max-height: 3em;
  }

  .button-visualizar-documentos {
    background-color: ${({ theme }) => theme.primary};
    color: white;
    font-weight: bold;
    font-family: Montserrat;
    font-size: 1.7rem;
    min-height: 3em;
    margin-left: 0.5em;
    max-height: 3em !important;
  }
  .DownloadIcon {
    padding: 0.5em;
  }
  .tabs {
    margin-left: 0.5em;
    margin-right: 0.5em;
    max-height: 3em;
  }

  #icon {
    padding: 0.7em 0.7em;
  }

  #bold {
    font-weight: bold;
    font-size: 1.5rem;
  }

  #blue {
    color: ${({ theme }) => theme.primary};
    font-size: 2.5rem;
    font-weight: bold;
  }

  .header,
  .row {
    display: flex;
    flex-direction: row;
  }

  .column {
    flex: 1;
    padding: 0em 0em;
    text-align: left;
    width: 25em;
  }

  hr {
    width: 100%;
    border: 1px solid;
    margin: 8px 0;
    border-color: #edf1f7;
  }

  .div-three {
    margin-bottom: 16px;
  }

  /* Media query for mobile devices */
  @media (max-width: 768px) {
    padding: 0px;
    .header,
    .row {
      flex-direction: column;
    }

    .column {
      width: 100%;
    }

    #card-title {
      flex-direction: column;
      max-width: 100%;
      font-size: 1.5rem;
    }

    #icon {
      padding: 0.5em 0.5em;
    }

    #blue {
      font-size: 2rem;
    }
  }
`;
